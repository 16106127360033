export const ASCENDING = "asc"
export const DESCENDING = "desc"
export const EMPTY_FILTER = ""
export const SORT_CREATED_AT = "createdAt"
export const SORT_TITLE = "title"
export const FILTER_ACTIVE = "active"
export const FILTER_RECIPE = "recipe"
export const FILTER_HACK = "hack"
export const FILTER_ITERATION = "iteration"
export const FILTER_SETUP = "setup"
export const FILTER_PRODUCT = "product"
export const FILTER_VIDEO = "video"
export const FILTER_INSIGHT = "insight"

export type IDEAS_SORT_TYPE = typeof SORT_TITLE | typeof SORT_CREATED_AT
export type IDEAS_FILTER_TYPE =
  | typeof FILTER_ACTIVE
  | typeof FILTER_RECIPE
  | typeof FILTER_HACK
  | typeof FILTER_ITERATION
  | typeof FILTER_SETUP
  | typeof FILTER_PRODUCT
  | typeof FILTER_VIDEO
  | typeof FILTER_INSIGHT
export type ORDER_BY_TYPE = typeof ASCENDING | typeof DESCENDING
