import { useRef } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Theme,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import { debounce } from "rambdax"
import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { ModalMode } from "./create-tri-category-modal"
import { FieldTypes } from "../admin-config-form"
import { useFormState } from "../admin-config-form/useFormState"

import { Tag } from "../../models/tag"
import { TriIdea } from "../../models/tri-idea"
import { TriBehavior } from "../../models/tri-behavior"
import { useHowTosSelectInput } from "../../utils/hooks/useHowTosSelectInput"

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    itemsCounterContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  }),
)

const useTriCategoryForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const classes = useStyles()
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    tags: tagsOptions,
    triIdeasPagination,
    triCategoriesPagination,
    triBehaviorsPagination,
  } = adminMslEventStoreModel

  const {
    newTriCategory,
    apiAdminCreateTriCategory,
    apiAdminEditTriCategory,
    apiAdminAddTagToTriCategory,
    apiAdminDeleteTriCategoryTag,
    apiAdminAddIdeaToTriCategory,
    apiAdminDeleteTriCategoryIdea,
    apiAdminAddBehaviorToTriCategory,
    apiAdminDeleteTriCategoryBehavior,
    resetNewTriCategory,
  } = triCategoriesPagination

  const {
    tags,
    ideas,
    behaviors,
    setTags,
    setIdeas,
    setBehaviors,
    createTriCategoryErrors: errors,
  } = newTriCategory

  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToTriCategory(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriCategoryTag(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleBehaviorChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriBehavior[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdea>,
  ) => {
    const areValidBehaviors = value.every((e) => e?.id)
    if (areValidBehaviors) {
      setBehaviors(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddBehaviorToTriCategory(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} behavior !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminDeleteTriCategoryBehavior(newTriCategory.id, option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} behavior !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string, searchAPI) => {
      try {
        if (query?.trim().length) {
          await searchAPI()
        }
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 2000),
  )

  const onCancel = () => {
    resetNewTriCategory()

    triIdeasPagination.resetPagination()
    triIdeasPagination.resetPaginationFilters()

    triBehaviorsPagination.resetTriBehaviors()
    triBehaviorsPagination.resetPaginationFilters()

    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }

    try {
      setLoading(true)

      if (mode === ModalMode.CREATE) {
        await apiAdminCreateTriCategory()
        setAndShowSnackbar({
          text: "Successfully created new category !",
          severity: "success",
        })
      } else {
        await apiAdminEditTriCategory()
        setAndShowSnackbar({
          text: "Successfully edited category !",
          severity: "success",
        })
      }

      triIdeasPagination.resetPagination()
      triIdeasPagination.resetPaginationFilters()

      triBehaviorsPagination.resetTriBehaviors()
      triBehaviorsPagination.resetPaginationFilters()

      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const handleBehaviorsSearch = async (_, value, isEndReached) => {
    const {
      next,
      query,
      setQuery,
      apiAdminGetTriBehaviors,
      apiAdminGetTriBehaviorsSearch,
      resetPagination,
    } = triBehaviorsPagination

    if (isEndReached) {
      if (query.trim().length) {
        await apiAdminGetTriBehaviorsSearch()

        return
      }

      if (next) {
        await apiAdminGetTriBehaviors()
      }
    } else {
      resetPagination()
      setQuery(value)

      const fetchNewBehaviors = () => {
        apiAdminGetTriBehaviorsSearch()
      }
      debouncedSearch.current(value, fetchNewBehaviors)
    }
  }

  const handleBehaviorFilters = (filterType, value) => {
    const { setSortBy, setLimit } = triBehaviorsPagination

    switch (filterType) {
      case "sortBy":
        setSortBy(value)
        break

      case "limit":
        setLimit(value)
        break

      default:
        setLimit(value)
        break
    }
  }

  const handleOnBlurSelect = () => {
    triBehaviorsPagination.resetPagination()
  }

  const handleSelectFocus = () => {
    if (!triBehaviorsPagination.query.trim().length) {
      triBehaviorsPagination.resetPagination()

      triBehaviorsPagination.apiAdminGetTriBehaviors()
    } else {
      triBehaviorsPagination.apiAdminGetTriBehaviorsSearch()
    }
  }

  const behaviorOptions = triBehaviorsPagination.behaviors.slice()
  const behaviorsValue = behaviors.slice()

  const { howTosSelectInputConfig } = useHowTosSelectInput({
    ideas,
    setIdeas,
    apiAddIdea: async (optionID) => {
      await apiAdminAddIdeaToTriCategory(newTriCategory.id, optionID)
    },
    apiDeleteIdea: async (optionID) => {
      await apiAdminDeleteTriCategoryIdea(newTriCategory.id, optionID)
    },
  })

  const triCategoryFormConfig = [
    {
      fieldName: "title",
      onChangeMethod: "setTitle",
      label: "Title",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title",
      },
    },
    {
      fieldName: "titleBareInfinitive",
      onChangeMethod: "setTitleBareInfinitive",
      label: "Title Bare Infinitive",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive",
      },
    },
    {
      fieldName: "titleBareInfinitive2ndPerson",
      onChangeMethod: "setTitleBareInfinitive2ndPerson",
      label: "Title Bare Infinitive 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title bare infinitive 2nd person",
      },
    },
    {
      fieldName: "titlePresentContinuous",
      onChangeMethod: "setTitlePresentContinuous",
      label: "Title Present Continuous",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous",
      },
    },
    {
      fieldName: "titlePresentContinuous2ndPerson",
      onChangeMethod: "setTitlePresentContinuous2ndPerson",
      label: "Title Present Continuous 2nd Person",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title present continuous 2nd person",
      },
    },
    {
      fieldName: "summary",
      onChangeMethod: "setSummary",
      label: "Summary",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter title summary",
      },
    },
    {
      fieldName: "iconURI",
      onChangeMethod: "setIconURI",
      label: "Icon URI",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter icon URI",
      },
    },

    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },

    howTosSelectInputConfig,

    {
      fieldName: "behaviors",
      onChangeMethod: "setBehaviors",
      label: "Hows",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: behaviorOptions,
        value: behaviorsValue,
        inputValue: triBehaviorsPagination.query,
        open: behaviorOptions.length > 0,
        onChange: handleBehaviorChange,
        loading: triBehaviorsPagination.loading,
        onInputChange: (_, value, reason) => {
          if (reason === "reset") {
            return
          }

          handleBehaviorsSearch(_, value, false)
        },
        onScrollEnd: () => handleBehaviorsSearch(null, null, true),
        onBlur: handleOnBlurSelect,
        onFocus: handleSelectFocus,
        renderOption: (props, option: TriBehavior) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        getOptionLabel: (option: TriBehavior) => option.title,
        itemCounter: (
          <div className={classes.itemsCounterContainer}>
            Total Number of Items: <b> {behaviorsValue.slice().length}</b>
          </div>
        ),
        filters: [
          {
            label: "Sort by",
            key: "sortBy",
            value: triBehaviorsPagination.sortBy,
            options: [
              { label: "Title", value: "title" },
              { label: "Created At", value: "createdAt" },
            ],
          },
          {
            label: "Limit",
            key: "limit",
            value: triBehaviorsPagination.limit,
            options: [
              { label: "25", value: 25 },
              { label: "50", value: 50 },
              { label: "100", value: 100 },
            ],
          },
        ],
        onFilterChange: (filterName, filterValue) => {
          handleBehaviorFilters(filterName, filterValue)
        },
        Input: {
          placeholder: "Select Hows",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    newTriCategory,
    loading,
    submitted,
    onCancel,
    onSubmit,
    values: newTriCategory,
    errors,
    triCategoryFormConfig,
  }
}

export { useTriCategoryForm }
