import React from "react"
import clsx from "clsx"
import {
  FormControl,
  Select,
  SelectChangeEvent,
  SelectProps,
  MenuItem,
} from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"

const useStyles = makeStyles((theme) =>
  createStyles({
    dropDownContainer: {
      borderRadius: 0,
      textTransform: "capitalize",
    },
    dropDownOutlined: {
      padding: 0,
      paddingRight: 16,
      fontSize: 18,
      lineHeight: "21px",
      color: "#2D353B",
      "font-weight": "500",
    },
    dropDownIcon: {
      color: "#2D353B",
      right: theme.spacing(1),
    },
    dropDownSelect: {
      padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
      paddingRight: `${theme.spacing(6)} !important`,
    },
    dropDownSelectWithStartIcon: {
      paddingLeft: `${theme.spacing(6)} !important`,
    },
    dropDownMenuList: {
      border: "1px solid #B0BABF",
      "box-shadow": "0px 4px 4px rgba(0, 0, 0, 0.25)",
      "border-radius": "0px 0px 4px 4px",
    },
    dropDownMenuItem: {
      fontSize: 15,
      color: "#2D353B",
    },
    startIconContainer: {
      position: "absolute",
      top: theme.spacing(1),
      left: theme.spacing(2),
    },
  }),
)

type VALUE = string | number | null

interface Option {
  value: VALUE
  label: string
}

interface FilterDropdownProps {
  value: VALUE
  onChange: (value: VALUE) => void
  options: Option[]
  label?: string
  defaultValue?: VALUE
  selectProps?: SelectProps
  StartIcon?: React.ReactNode
  onClose?: () => void
  children?: React.ReactNode
}

const FilterDropdown = ({
  value,
  onChange,
  options,
  label,
  selectProps,
  StartIcon,
  onClose,
  children,
}: FilterDropdownProps) => {
  const classes = useStyles()

  const handleChange = (event: SelectChangeEvent) => {
    onChange(event.target.value as VALUE)
  }

  const showStartIcon = Boolean(StartIcon)

  return (
    <FormControl>
      <Select
        value={value}
        onChange={handleChange}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        onClose={onClose}
        classes={{
          root: classes.dropDownContainer,
          outlined: classes.dropDownOutlined,
          icon: classes.dropDownIcon,
          select: clsx(classes.dropDownSelect, {
            [classes.dropDownSelectWithStartIcon]: showStartIcon,
          }),
        }}
        MenuProps={{
          classes: {
            paper: classes.dropDownMenuList,
          },
        }}
        IconComponent={(props) => (
          <ArrowDropDownIcon fontSize="large" {...props} />
        )}
        {...selectProps}>
        {Boolean(label) && (
          <MenuItem value="" className={classes.dropDownMenuItem}>
            {label}
          </MenuItem>
        )}

        {options.map((option) => (
          <MenuItem
            value={option.value}
            className={classes.dropDownMenuItem}
            key={option.value}>
            {option.label}
          </MenuItem>
        ))}

        {children}
      </Select>

      {showStartIcon && (
        <div className={classes.startIconContainer}>{StartIcon}</div>
      )}
    </FormControl>
  )
}

export default FilterDropdown
