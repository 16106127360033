import { GridColDef } from "@mui/x-data-grid"

import { dateColumnSortMethod } from "../admin-msl-events-list/utils"

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    filterable: false,
  },
  {
    field: "title",
    headerName: "Title",
  },

  {
    field: "iconURI",
    headerName: "Icon URI",
    sortable: false,
    filterable: false,
  },
  {
    field: "iconURILarge",
    headerName: "Icon URI Large",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    filterable: false,
    sortComparator: dateColumnSortMethod,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]
