import React from "react"
import { Theme, Typography } from "@material-ui/core"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import { CustomSwitch } from "../custom-switch"

import { useStores } from "../../models/root-store"
import { useSnackbars } from "../../components/use-snackbar"
import { noop } from "../../utils"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    switcherContainer: {
      display: "flex",
    },
    label: {
      fontWeight: "bold",
    },
  }),
)

const ActiveSlider = ({ ideaID }: { ideaID: string }) => {
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()

  const { triIdeasPagination } = adminMslEventStoreModel
  const { ideas, apiAdminEditTriIdeaActiveStatus } = triIdeasPagination
  const {
    setSnackbarText = noop,
    setShowSnackbar = noop,
    setSnackbarSeverity = noop,
  } = useSnackbars()

  const currentIdea = ideas.find((idea) => idea.id === ideaID)
  const { active, setIsActive } = currentIdea || {}

  const toggleActiveIdea = async () => {
    try {
      setIsActive(!active)
      if (active) {
        await apiAdminEditTriIdeaActiveStatus({
          ...currentIdea,
          active: false,
        })
      } else {
        await apiAdminEditTriIdeaActiveStatus({
          ...currentIdea,
          active: true,
        })
      }
      setSnackbarSeverity("success")
      const activeStatus = !active ? "activated" : "deactivated"
      setSnackbarText(`Successfully ${activeStatus} idea !`)
      setShowSnackbar(true)
    } catch (error) {
      setIsActive(!active)
      setSnackbarSeverity("error")
      setSnackbarText(error.message)
      setShowSnackbar(true)
    }
  }

  return (
    <div className={classes.switcherContainer}>
      <CustomSwitch
        checked={active}
        onChange={toggleActiveIdea}
        activeLabel=""
        inActiveLabel=""
        labelSwitchSpacing={1}
        activeViewColor={"#00CC7E"}
        inActiveViewColor={"#808080"}
      />
      <Typography ml={1} className={classes.label}>
        {active ? "On" : "Off"}
      </Typography>
    </div>
  )
}

export { ActiveSlider }
