import React from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
} from "@material-ui/core"

import { useSnackbars } from "../use-snackbar"
import { useStores } from "../../models/root-store"
import { Tag } from "../../models/tag"
import { Category } from "../../models/category"
import { FieldTypes } from "../admin-config-form"
import { Trainer } from "../../models/msl-event/trainer"
import { Topic } from "../../models/msl-event/topic"
import { ModalMode } from "./create-msl-event-modal"
import { useFormState } from "../admin-config-form/useFormState"

const useMslEventForm = ({
  isModalOpen,
  mode,
  closeModal,
}: {
  isModalOpen: boolean
  mode: ModalMode
  closeModal: () => void
}) => {
  const { setAndShowSnackbar } = useSnackbars()

  const { adminMslEventStoreModel } = useStores()
  const {
    newMslEvent,
    editMslEvent,
    mindsetTrainers,
    eventTopics,
    tags: tagsOptions,
    categories,
    editMslCategories,
    resetNewMslEvent,
    apiAdminCreateMslEvent,
    apiAdminEditMslEvent,
    apiAdminEpisodesUploadContent,
    apiAdminAddTagToEvent,
    apiAdminRemoveTagToEvent,
    apiAdminAddEpisodeToCategory,
    apiAdminRemoveEpisodeToCategory,
  } = adminMslEventStoreModel

  const mslEventData =
    mode === ModalMode.ADD_NEW_EVENT ? newMslEvent : editMslEvent

  const {
    trainerIDV2,
    topicID,
    tags,
    setTags,
    uploadFiles,
    setUploadFile,
    createMslEventErrors,
  } = mslEventData

  const { hlsVideoURL: hlsVideoURLError, ...errors } = createMslEventErrors
  const { validateErrors, loading, setLoading, submitted } = useFormState({
    isModalOpen,
    errors,
  })

  const handleTagsChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Tag[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Tag>,
  ) => {
    event.preventDefault()
    const areValidTags = value.every((e) => e?.id)
    if (areValidTags) {
      setTags(value.slice())
    }

    const { option } = details
    try {
      if (reason === "selectOption") {
        await apiAdminAddTagToEvent(mslEventData.id, option)
        setAndShowSnackbar({
          text: `Successfully added ${option.displayName} tag !`,
        })
      }
      if (reason === "removeOption") {
        await apiAdminRemoveTagToEvent(mslEventData.id, option)
        setAndShowSnackbar({
          text: `Successfully removed ${option.displayName} tag !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const handleCategoriesChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: Category[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Category>,
  ) => {
    event.preventDefault()
    const { option: selectedCategory } = details
    try {
      if (reason === "selectOption") {
        selectedCategory.addEvent(editMslEvent)
        await apiAdminAddEpisodeToCategory(selectedCategory.id, mslEventData.id)
        setAndShowSnackbar({
          text: `Successfully added episode ${editMslEvent.name} to ${selectedCategory.name} category !`,
        })
      }
      if (reason === "removeOption") {
        selectedCategory.removeEvent(editMslEvent)
        await apiAdminRemoveEpisodeToCategory(
          selectedCategory.id,
          mslEventData.id,
        )
        setAndShowSnackbar({
          text: `Successfully removed episode ${editMslEvent.name} to ${selectedCategory.name} category !`,
        })
      }
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const onCancel = () => {
    resetNewMslEvent()
    closeModal()
  }

  const onSubmit = async () => {
    const isValid = validateErrors()
    if (!isValid) {
      return
    }
    try {
      setLoading(true)
      await apiAdminEpisodesUploadContent({
        isEditMode: mode === ModalMode.EDIT_EVENT,
      })
      if (mode === ModalMode.ADD_NEW_EVENT) {
        await apiAdminCreateMslEvent()
        setAndShowSnackbar({ text: "Successfully created event !" })
      } else {
        await apiAdminEditMslEvent()
        setAndShowSnackbar({ text: "Successfully edited event !" })
      }
      closeModal()
    } catch (error) {
      setAndShowSnackbar({ text: error.message, severity: "error" })
    } finally {
      setLoading(false)
    }
  }

  const formConfig = [
    {
      fieldName: "name",
      onChangeMethod: "setName",
      label: "Event Name",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter name here",
      },
    },
    {
      fieldName: "description",
      onChangeMethod: "setDescription",
      label: "Event Description",
      required: true,
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter description here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "isFeatured",
      onChangeMethod: "setIsFeatured",
      label: "Is featured ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "audioOnly",
      onChangeMethod: "setAudioOnly",
      label: "Is audio only ?",
      fieldType: FieldTypes.Switch,
    },
    {
      fieldName: "duration",
      onChangeMethod: "setDuration",
      label: "Event Duration (Value in seconds)",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter duration here",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
    },
    {
      fieldName: "posterImageURL",
      onChangeMethod: "setPosterImageURL",
      label: "Poster Image Url",
      required: true,
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter poster image url here",
        filePickerProps: {
          label: "Select poster image",
          fileType: "train_episode_poster_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.posterImageURL,
        },
      },
    },
    {
      fieldName: "trainerIDV2",
      onChangeMethod: "setTrainerIDV2",
      label: "Trainer",
      required: true,
      fieldType: FieldTypes.Select,
      componentProps: {
        options: mindsetTrainers.slice(),
        value: mindsetTrainers
          .slice()
          .find((trainer) => trainer.id === trainerIDV2),
        getOptionLabel: (option: Trainer) => option.fullName,
        Input: {
          placeholder: "Select a trainer",
        },
      },
    },
    {
      fieldName: "topicID",
      onChangeMethod: "setTopicId",
      label: "Topic (DEPRECATED, please use Categories instead)",
      fieldType: FieldTypes.Select,
      componentProps: {
        options: eventTopics.slice(),
        value: eventTopics.slice().find((topic) => topic.id === topicID),
        getOptionLabel: (option: Topic) => option.name,
        Input: {
          placeholder: "Select a topic",
        },
      },
    },
    {
      fieldName: "scheduledStartTime",
      onChangeMethod: "setScheduledStartTime",
      label: "Event Start Date",
      required: true,
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "scheduledEndTime",
      onChangeMethod: "setScheduledEndTime",
      label: "Event End Date",
      required: true,
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "publishStartTime",
      onChangeMethod: "setPublishStartTime",
      label: "Publish Start Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "publishEndTime",
      onChangeMethod: "setPublishEndTime",
      label: "Publish End Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "previewStartTime",
      onChangeMethod: "setPreviewStartTime",
      label: "Coming Soon Start Date",
      fieldType: FieldTypes.DateTimePicker,
    },
    {
      fieldName: "hlsVideoURL",
      onChangeMethod: "setHlsVideoURL",
      label: "Adaptive Bitrate URL (HLS)",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter the URL ending with .m3u8",
        helperText: hlsVideoURLError,
        sx: hlsVideoURLError && {
          "& fieldset": { borderColor: "orange !important" },
          "& label, & P": { color: "orange !important" },
        },
      },
    },
    {
      fieldName: "recordingAvailable",
      onChangeMethod: "setRecordingAvailable",
      label: "Is recording available ?",
      fieldType: FieldTypes.Switch,
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "recordingURL",
      onChangeMethod: "setRecordingURL",
      label: "Recording URL",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter the recording url here or select locally",
        filePickerProps: {
          label: "Select recording",
          fileType: "train_episode_recording",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.recordingURL,
          mode: ["video", "audio"],
        },
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "audioOnlyImageURL",
      onChangeMethod: "setAudioOnlyImageURL",
      label: "Audio-only image URL",
      fieldType: FieldTypes.FilePicker,
      componentProps: {
        placeholder: "Enter audio-only image URL or select locally",
        filePickerProps: {
          label: "Select image",
          fileType: "train_episode_audio_only_image",
          onChange: setUploadFile,
          uploadFiles: uploadFiles.audioOnlyImageURL,
        },
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "script",
      onChangeMethod: "setScript",
      label: "Script",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter script",
        multiline: true,
        minRows: 1,
        maxRows: 6,
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "searchKeywords",
      onChangeMethod: "setSearchKeywords",
      label: "Search Keywords",
      fieldType: FieldTypes.TextInput,
      componentProps: {
        placeholder: "Enter the search keywords",
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "tags",
      onChangeMethod: "setTags",
      label: "Tags",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: tagsOptions.slice(),
        value: tags.slice(),
        onChange: handleTagsChange,
        renderOption: (props, option: Tag) => (
          <li {...props} key={option.id}>
            {option.displayName}
          </li>
        ),
        Input: {
          placeholder: "Select Tags",
        },
        showTags: true,
        tagDisplayNameField: "displayName",
      },
      showOnlyOnEditMode: true,
    },
    {
      fieldName: "categories",
      label: "Categories",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        disableClearable: true,
        options: categories.slice(),
        value: editMslCategories,
        onChange: handleCategoriesChange,
        getOptionLabel: (option: Category) => option.name,
        Input: {
          placeholder: "Select Categories",
        },
        showTags: true,
        tagDisplayNameField: "name",
      },
      showOnlyOnEditMode: true,
    },
  ]

  return {
    mslEventData,
    errors,
    loading,
    submitted,
    onCancel,
    onSubmit,
    formConfig,
  }
}

export { useMslEventForm }
