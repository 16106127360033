import { observer } from "mobx-react-lite"
import { Stack } from "@material-ui/core"

import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { useTriProductForm } from "./useTriProductForm"
import { TriProduct } from "../../models/tri-product"
import { AssociatedHowTos } from "./associated-howTos"

export enum ModalMode {
  CREATE,
  EDIT,
}

const modalModeLabels = {
  [ModalMode.CREATE]: {
    headerLabel: "Create new Product",
    buttonLabel: "Create",
  },
  [ModalMode.EDIT]: { headerLabel: "Edit Product", buttonLabel: "Save" },
}

interface CreateTriProductModalProps {
  open: boolean
  closeModal: () => void
  mode: ModalMode
}

const CreateTriProductModal = observer(
  ({ mode, open, closeModal }: CreateTriProductModalProps) => {
    const {
      newTriProduct,
      loading,
      submitted,
      onCancel,
      onSubmit,
      triProductFormConfig,
    } = useTriProductForm({ mode, closeModal, isModalOpen: open })

    const { createTriProductErrors: errors } = newTriProduct

    const configFormFiltered = triProductFormConfig.filter(
      ({ fieldName }) => fieldName,
    )

    return (
      <FormModal
        open={open}
        modalTitle={modalModeLabels[mode].headerLabel}
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel={modalModeLabels[mode].buttonLabel}
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<TriProduct>
            submitted={submitted}
            values={newTriProduct}
            errors={errors}
            isEditMode={mode === ModalMode.EDIT}
            formConfig={configFormFiltered}
          />
        </Stack>

        <AssociatedHowTos howTos={newTriProduct.howTos} />
      </FormModal>
    )
  },
)
export { CreateTriProductModal }
