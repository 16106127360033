import { useCallback } from "react"
import {
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
} from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"

interface UseTableParams {
  fetchProducts: () => Promise<void>
  restorePaginationState: () => void
}

const useTable = ({
  fetchProducts,
  restorePaginationState,
}: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()
  const { triBehaviorsPagination } = adminMslEventStoreModel
  const {
    apiAdminGetTriBehaviors,
    apiAdminGetTriBehaviorsSearch,
    query,
    setLoading,
    setSortBy,
    setQuery,
    setCount,
    setNext,
    setPage,
    resetTriBehaviors,
    resetPagination,
  } = triBehaviorsPagination

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      restorePaginationState()
      const sortOption = sortModel[0]

      const handleSort = async () => {
        if (query.trim().length) {
          setCount(0)
          setNext(null)
          setPage(1)
          resetTriBehaviors()

          await apiAdminGetTriBehaviorsSearch()
        } else {
          resetPagination()
          fetchProducts()
        }
      }

      if (sortOption) {
        setSortBy(sortOption.field)
      } else {
        setSortBy(null)
      }

      await handleSort()
    },
    [
      query,
      setCount,
      setNext,
      setPage,
      setLoading,
      setSortBy,
      fetchProducts,
      apiAdminGetTriBehaviorsSearch,
      resetPagination,
      resetTriBehaviors,
      restorePaginationState,
    ],
  )

  const handleFilterModelChange = useCallback(
    async (
      filterModel: GridFilterModel,
      details: GridCallbackDetails<"filter">,
    ) => {
      const value = filterModel?.items?.[0]?.value

      if (details.reason === "upsertFilterItem") {
        setLoading(true)
        resetPagination()
        restorePaginationState()

        if (value) {
          setQuery(value)

          await apiAdminGetTriBehaviorsSearch()

          return
        } else {
          await apiAdminGetTriBehaviors()
          return
        }
      }
    },
    [
      setLoading,
      setQuery,
      apiAdminGetTriBehaviorsSearch,
      apiAdminGetTriBehaviors,
      resetPagination,
      restorePaginationState,
    ],
  )

  return {
    handleSortModelChange,
    handleFilterModelChange,
  }
}

export { useTable }
