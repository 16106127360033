import React from "react"

import { Stack, Typography, Box, Chip, Theme } from "@mui/material"
import { makeStyles, createStyles } from "@material-ui/styles"
import clsx from "clsx"

import { typography } from "../../services/theme/typography"
import { ScoreType } from "../../models/affinity-group"
import { MetricsTooltip } from "./metrics-tooltip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    metricContainer: {
      backgroundColor: "#F9FAFB",
      borderRadius: theme.spacing(2),
      padding: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(1),
      width: "100%",
    },
    metricHeaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      width: "100%",
      columnGap: theme.spacing(1),
    },
    title: {
      fontSize: theme.spacing(2),
      fontFamily: typography.circularXXMedium.fontFamily,
      fontWeight: 600,
      color: "#2D353B",
    },
    statusChip: {
      backgroundColor: "#0A8052",
      color: "#FFFFFF",
      fontFamily: typography.circularXXBold.fontFamily,
      borderRadius: theme.spacing(2),
    },
    progressBarContainer: {
      display: "flex",
      width: "100%",
      height: theme.spacing(1),
      backgroundColor: "#F9F9F9",
      borderRadius: theme.spacing(1),
      overflow: "hidden",
      marginTop: theme.spacing(1),
      columnGap: theme.spacing(0.25),
    },
    drawerProgressBarContainer: {
      width: "70%",
      height: theme.spacing(1.42),
      columnGap: theme.spacing(0.5),
      backgroundColor: "#4070B4",
    },
    drawerProgressSegment: {
      flex: 1,
      backgroundColor: "#27446D",
    },
    progressSegment: {
      flex: 1,
      backgroundColor: "#C7CFD4",
    },
    drawerTitle: {
      fontSize: theme.spacing(1.5),
      color: "#FFFFFF",
      fontFamily: typography.circularXXMedium.fontFamily,
      fontWeight: 500,
    },
    lowSegment: {
      backgroundColor: "#D14715",
    },
    mediumSegment: {
      backgroundColor: "#986E04",
    },
    highSegment: {
      backgroundColor: "#0A8052",
    },
    activeDrawerProgress: {
      backgroundColor: "#FFFFFF !important",
    },
  }),
)

interface EngagementMetricsProps {
  label: string
  type?: string
  score?: ScoreType
  chipLabelStyles?: string
  labelStyles?: string
  metricsMainContainerStyles?: string
  hoverDescription?: string
}

interface EngagementMetricChipProps {
  score: string
  statusChipStyles?: string
}

const EngagementMetricChip = ({
  score,
  statusChipStyles,
}: EngagementMetricChipProps) => {
  const classes = useStyles()

  return (
    <Chip
      label={score ? score.charAt(0).toUpperCase() + score.slice(1) : ""}
      className={clsx(classes.statusChip, statusChipStyles, {
        [classes.lowSegment]: score === "low",
        [classes.mediumSegment]: score === "medium",
        [classes.highSegment]: score === "high",
      })}
    />
  )
}

const EngagementMetrics = ({
  label,
  type,
  score,
  chipLabelStyles,
  labelStyles,
  metricsMainContainerStyles,
  hoverDescription,
}: EngagementMetricsProps) => {
  const classes = useStyles()

  if (type === "drawer") {
    return (
      <Stack pl={1.5}>
        <Typography className={classes.drawerTitle}>{label}</Typography>

        <Box
          className={clsx(
            classes.progressBarContainer,
            classes.drawerProgressBarContainer,
          )}>
          <Box
            className={clsx(classes.drawerProgressSegment, {
              [classes.activeDrawerProgress]:
                score === "low" || score === "medium" || score === "high",
            })}
          />

          <Box
            className={clsx(classes.drawerProgressSegment, {
              [classes.activeDrawerProgress]:
                score === "medium" || score === "high",
            })}
          />

          <Box
            className={clsx(classes.drawerProgressSegment, {
              [classes.activeDrawerProgress]: score === "high",
            })}
          />
        </Box>
      </Stack>
    )
  }

  return (
    <MetricsTooltip title={hoverDescription || ""}>
      <Box
        className={clsx(classes.metricContainer, metricsMainContainerStyles)}>
        <Box className={classes.metricHeaderContainer}>
          <Typography className={clsx(classes.title, labelStyles)}>
            {label}
          </Typography>

          {score && (
            <EngagementMetricChip
              score={score}
              statusChipStyles={chipLabelStyles}
            />
          )}
        </Box>

        <Box className={classes.progressBarContainer}>
          <Box
            className={clsx(classes.progressSegment, {
              [classes.lowSegment]: score === "low",
              [classes.mediumSegment]: score === "medium",
              [classes.highSegment]: score === "high",
            })}
          />

          <Box
            className={clsx(classes.progressSegment, {
              [classes.mediumSegment]: score === "medium",
              [classes.highSegment]: score === "high",
            })}
          />

          <Box
            className={clsx(classes.progressSegment, {
              [classes.highSegment]: score === "high",
            })}
          />
        </Box>
      </Box>
    </MetricsTooltip>
  )
}

export { EngagementMetrics, EngagementMetricChip }
