import { GridColDef } from "@mui/x-data-grid"

export const columns: GridColDef[] = [
  {
    field: "id",
    headerName: "ID",
    sortable: false,
    filterable: false,
  },
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    filterable: true,
  },
  {
    field: "displayName",
    headerName: "Display Name",
    sortable: false,
    filterable: false,
  },
  {
    field: "description",
    headerName: "Description",
    sortable: false,
    filterable: false,
  },
  {
    field: "imageURL",
    headerName: "Image",
    sortable: false,
    filterable: false,
  },
  {
    field: "type",
    headerName: "Type",
    sortable: false,
    filterable: false,
  },
  {
    field: "isBackup",
    headerName: "Is Backup",
    sortable: false,
    filterable: false,
  },
  {
    field: "isStale",
    headerName: "Is Stale",
    sortable: false,
    filterable: false,
  },
  {
    field: "createdAt",
    headerName: "Created At",
    sortable: false,
    filterable: false,
  },
  {
    field: "actions",
    headerName: "",
    sortable: false,
    filterable: false,
  },
]
