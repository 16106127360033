import { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridCellProps, useGridApiRef } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { LIMIT } from "../../models/tri-ideas-pagination"
import { CreateTriCategoryModal, ModalMode } from "./create-tri-category-modal"
import { useTable } from "./useTable"
import { columns } from "./table-config"
import { TriCategory } from "../../models/tri-category"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "20% !important",
      maxWidth: "20% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
    },
    tableColumnHeader: {
      width: "20% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },
  }),
)

const AdminTriCategoriesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [
    isCreateTriCategoryModalOpen,
    toggleCreateTriCategoryModalOpen,
  ] = useState(false)
  const [triCategoryModalMode, setTriCategoryModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )
  const tableRef = useGridApiRef()

  const { adminMslEventStoreModel } = useStores()
  const {
    triCategoriesPagination,
    triIdeasPagination,
    triBehaviorsPagination,
    apiAdminGetTags,
  } = adminMslEventStoreModel
  const { setNewTriCategory, resetNewTriCategory } = triCategoriesPagination

  const {
    categories,
    totalCount,
    count,
    loading,
    page: currentPage,
    limit: currenLimit,
    query,
    apiAdminGetTriCategories,
    apiAdminSearchTriCategories,
    setLoading,
    setNext,
    setLimit,
    setPage,
    resetTriCategories,
    resetPagination,
  } = triCategoriesPagination

  const categoriesAreFtechedByQuery = query?.trim().length

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetTags()
      } catch (e) {}
      toggleFirstLoading(false)
      setLoading(false)
    }

    resetPagination()
    fetchCategories()
    initialFetch()

    return () => {
      triCategoriesPagination.resetPagination()

      triIdeasPagination.resetPagination()
      triIdeasPagination.resetPaginationFilters()

      triBehaviorsPagination.resetPagination()
      triBehaviorsPagination.resetPaginationFilters()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchCategories = async () => {
    try {
      await triCategoriesPagination.apiAdminGetTriCategories()
      toggleFirstLoading(false)
      setLoading(false)
    } catch (e) {
      toggleFirstLoading(false)
      setLoading(false)
    }
  }

  const restorePaginationState = () => {
    const state = tableRef.current.exportState()

    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
        },
      },
    }
    tableRef.current.restoreState(restoredState)
  }

  const openCreateTriCategoryModal = () => {
    resetNewTriCategory()
    setTriCategoryModalMode(ModalMode.CREATE)
    toggleCreateTriCategoryModalOpen(true)
  }

  const openEditTriCategoryModal = useCallback((event: TriCategory) => {
    setNewTriCategory(event)
    setTriCategoryModalMode(ModalMode.EDIT)
    toggleCreateTriCategoryModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriCategoryModal = () => {
    toggleCreateTriCategoryModalOpen(false)
  }

  const handlePageSizeChange = async (pageSize: number) => {
    restorePaginationState()

    setLoading(true)
    setNext(0)
    setLimit(pageSize)
    resetTriCategories()

    if (categoriesAreFtechedByQuery) {
      await apiAdminSearchTriCategories({ query })

      return
    }

    await apiAdminGetTriCategories()

    return
  }

  const handleNextPageChange = async () => {
    setLoading(true)
    setPage(currentPage + 1)

    if (categoriesAreFtechedByQuery) {
      await apiAdminSearchTriCategories({ query })

      return
    }

    await apiAdminGetTriCategories()
  }

  const handlePaginationChange = async (pageData, reasonData) => {
    const { page, pageSize } = pageData
    const { reason } = reasonData

    if (reason !== "setPaginationModel") {
      return
    }

    if (currenLimit !== pageSize) {
      handlePageSizeChange(pageSize)

      return
    }

    if (currentPage < page + 1) {
      handleNextPageChange()
    }
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTriCategoryModal={openEditTriCategoryModal}
          triCategoryID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { handleSortModelChange, handleFilterModelChange } = useTable({
    fetchCategories,
    restorePaginationState,
  })

  const emptyStateLabel = query.trim().length
    ? "No matching What's found"
    : "When you create some how tos, they will appear here"

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>What's</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTriCategoryModal}>
          Add What's
        </Button>
      </Stack>

      <CustomGridTable
        apiRef={tableRef}
        columns={columns}
        aria-label="tri-categories-table"
        cell={MemoTableCell}
        rows={loading ? [] : categories.slice()}
        onPaginationModelChange={handlePaginationChange}
        rowCount={categoriesAreFtechedByQuery ? count : totalCount}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: LIMIT,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        emptyStateLabel={emptyStateLabel}
        loading={loading || firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
        sortingMode="server"
        filterMode="server"
        onSortModelChange={handleSortModelChange}
        onFilterModelChange={handleFilterModelChange}
      />

      <CreateTriCategoryModal
        open={isCreateTriCategoryModalOpen}
        closeModal={closeCreateTriCategoryModal}
        mode={triCategoryModalMode}
      />
    </div>
  )
})

export { AdminTriCategoriesList }
