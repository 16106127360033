import { Instance, SnapshotOut, types, isAlive } from "mobx-state-tree"
import { withEnvironment, withRootStore } from "../extensions"

import { uniqBy, prop } from "ramda"

import { TriIdeaProduct, TriIdeaProductModel } from "../tri-idea-product"

export const LIMIT = 25

/**
 * Represents a pagination model.
 */
export const TriIdeaProductsPaginationModel = types
  .model("TriIdeaProductsPagination")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    loading: types.optional(types.boolean, false),
    query: types.optional(types.maybeNull(types.string), ""),
    next: types.optional(types.maybeNull(types.number), null),
    previous: types.optional(types.maybeNull(types.number), null),
    page: types.optional(types.maybeNull(types.number), null),
    limit: types.optional(types.maybeNull(types.number), LIMIT),
    products: types.optional(types.array(TriIdeaProductModel), []),
    totalCount: types.optional(types.maybeNull(types.number), null),
  })
  .actions((self) => ({
    setLoading(value: boolean) {
      self.loading = value
    },

    setQuery(value: string) {
      self.query = value
    },

    setNext(value: number) {
      self.next = value
    },

    setPage(value: number) {
      self.page = value
    },

    setLimit(value: number) {
      self.limit = value
    },

    setPagination(value: {
      next: number
      query: string
      totalCount: number
      previous: number
    }) {
      self.next = value.next
      self.previous = value.previous
      self.query = value.query
      self.totalCount = value.totalCount
    },

    setTriProducts(value: TriIdeaProduct[]) {
      self.products.replace(uniqBy(prop("id"), [...self.products, ...value]))
    },

    resetTriProducts() {
      if (isAlive(self.products)) {
        self.products.clear()
      }
    },
  }))
  .actions((self) => ({
    async apiAdminGetTriIdeaProducts() {
      const { api } = self.environment
      const { next, limit, query, setLoading } = self

      setLoading(true)

      const { kind, data } = await api.adminGetTriIdeaProducts({
        next,
        limit,
        query,
      })

      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const triIdeaProductsPagination = {
        next: data.data.next,
        limit: data.data.limit,
        totalCount: data.data.count,
        previous: data.data.previous,
        query,
      }

      self.setTriProducts(data.data.products)
      self.setPagination(triIdeaProductsPagination)
      setLoading(false)
    },
  }))
  .actions((self) => ({
    resetPagination() {
      self.resetTriProducts()
      self.next = null
      self.query = ""
      self.page = 0
      self.previous = 0
    },
  }))

export interface TriIdeaProductsPagination
  extends Instance<typeof TriIdeaProductsPaginationModel> {}
export interface TriIdeaProductsPaginationSnapshot
  extends SnapshotOut<typeof TriIdeaProductsPaginationModel> {}
