import { types, Instance, SnapshotOut, clone } from "mobx-state-tree"
import * as customTypes from "../types"
import { validate, ValidationRules } from "../../utils/validate"

import { Tag, TagModel } from "../tag"
import { TriIdeaRecipe, TriIdeaRecipeModel } from "../tri-idea-recipe"
import { TriIdeaProduct, TriIdeaProductModel } from "../tri-idea-product"

export const TriIdeaModel = types
  .model("TriIdea")
  .props({
    id: types.identifier,
    personID: types.maybeNull(types.string),
    title: types.optional(types.maybeNull(types.string), ""),
    summary: types.optional(types.maybeNull(types.string), ""),
    type: types.optional(types.maybeNull(types.string), ""),
    metadata: types.optional(types.maybeNull(types.string), null),
    active: types.optional(types.boolean, false),
    isPublic: types.optional(types.boolean, false),
    recipe: types.optional(types.maybeNull(TriIdeaRecipeModel), null),
    product: types.maybeNull(TriIdeaProductModel),
    tags: types.optional(types.array(TagModel), []),
    createdAt: types.optional(customTypes.iso8601, null),
    updatedAt: types.optional(customTypes.iso8601, null),
  })
  .actions((self) => ({
    setPersonID(value: string) {
      self.personID = value
    },
    setTitle(value: string) {
      self.title = value
    },
    setIsActive(value: boolean) {
      self.active = value
    },
    setSummary(value: string) {
      self.summary = value
    },
    setType(value: string) {
      self.type = value
    },
    setMetadata(value: string) {
      self.metadata = value
    },
    setActive(value: boolean) {
      self.active = value
    },
    setTags(value: Tag[]) {
      self.tags.replace(value.map((e) => clone(e)))
    },
    setRecipe(value: TriIdeaRecipe | null) {
      self.recipe = value ? clone(value) : null
    },
    setProduct(value: TriIdeaProduct | null) {
      self.product = value ? clone(value) : null
    },
    reset() {
      self.id = "-1"
      self.personID = ""
      self.title = ""
      self.summary = ""
      self.type = ""
      self.metadata = null
      self.active = false
      self.recipe = null
      self.product = null
      self.tags.replace([])
      self.createdAt = null
      self.updatedAt = null
    },
  }))
  .views((self) => ({
    get createTriIdeaErrors() {
      return validate(CREATE_NEW_TRI_IDEA_RULES, self)
    },
    get isEditMode() {
      return self.id !== "-1"
    },
  }))

const CREATE_NEW_TRI_IDEA_RULES: ValidationRules = {
  personID: {
    presence: { allowEmpty: false, message: "required" },
  },
  title: {
    presence: { allowEmpty: false, message: "required" },
  },
  summary: {
    validateTriIdeaSummary: {},
  },
  type: {
    presence: { allowEmpty: false, message: "required" },
  },
}

export interface TriIdea extends Instance<typeof TriIdeaModel> {}
export interface TriIdeaSnapshot extends SnapshotOut<typeof TriIdeaModel> {}
