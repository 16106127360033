import React, { useState } from "react"
import { ListChildComponentProps } from "react-virtualized"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import Stack from "@material-ui/core/Stack"
import Divider from "@material-ui/core/Divider"
import Button from "@material-ui/core/Button"
import ListItem from "@mui/material/ListItem"
import ListItemText from "@mui/material/ListItemText"
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined"
import NotificationsOffOutlinedIcon from "@mui/icons-material/NotificationsOffOutlined"
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined"
import { observer } from "mobx-react-lite"

import { useSnackbars } from "../use-snackbar"
import { noop } from "../../utils"
import { useStores } from "../../models/root-store"
import { Post } from "../../models/post"
import { MutePostDialog } from "./mute-post-dialog"
import { ReleasePostDialog } from "./release-post"
import { ShowMorePopup } from "./popopver"
import { ReactComponent as ReleaseIcon } from "../../assets/images/release.svg"

import { Typography } from "@mui/material"

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
      background: "#F5F6F7",
      borderRadius: theme.spacing(0.5),
      marginBottom: theme.spacing(2),
      display: "flex",
      border: "1px solid #C7CFD4",
      alignItems: "start",
    },
    alertModeItem: {
      background: "#FDDDDD",
      border: "1px solid #FF0000",
      marginRight: 0,
    },
    quarantineModeItem: {
      background: "#FFEEDA",
      border: "1px solid #FF8A00",
    },
    focusVisible: {
      background: "transparent",
    },
    userInfoContainer: {
      flex: 1,
    },
    postInfoContainer: {
      width: "100%",
      flexWrap: "wrap",
    },
    infoTextRoot: {
      flex: "initial",
    },
    infoText: {
      fontSize: 16,
      lineHeight: "20px",
      "font-weight": "500",
      color: "#2D353B",
    },
    nameText: {
      "font-weight": "900",
    },
    dateText: {
      color: "#B2B9BD",
    },
    gratitudeTagText: {
      color: "#039CAD",
    },
    intentionTagText: {
      color: "#897FDE",
    },
    messageText: {
      fontSize: 16,
      lineHeight: "20px",
      color: "#63646D",
      overflowWrap: "anywhere",
    },
    alertText: {
      color: "#FF0000 !important",
    },
    managePersonActive: {
      color: "#0A8052 !important",
    },
    actionButton: {
      width: "80px",
      padding: 0,
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#485359",
      },
      "&:active": {
        color: "#2D353B",
      },
    },
    actionButtonText: {
      fontSize: 15,
      lineHeight: "18px",
      color: "#677278",
      fontWeight: "normal",
      "text-transform": "none",
    },
    actionButtonIcon: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
    quarantineLabel: {
      fontSize: theme.spacing(2),
      color: "#FF8A00",
      fontWeight: 700,
      marginRight: theme.spacing(1),
      textAlign: "center",
    },
    quarantineValue: { fontSize: theme.spacing(2), color: "#677278" },
  }),
)

type FeedListItemProps = {}

export const FeedListItem = observer(
  ({ index, style }: FeedListItemProps & ListChildComponentProps) => {
    const classes = useStyles()

    const [openMuteDialog, toggleMuteDialog] = useState(false)
    const [openReleasePostDialog, toggleReleasePostDialog] = useState(false)

    const { adminToolsStore } = useStores()
    const {
      feedSourceList,
      newUserPost,
      apiAdminMutePost,
      apiAdminUnMutePost,
      apiAdminBanUser,
      apiAdminUnBanUser,
      apiAdminReleaseQuarantinedPost,
      manuallyToggleMutePost,
      manuallyToggleUserBannedOnPosts,
      getPersonData,
      selectedPerson,
      toggleModalPostOpen,
    } = adminToolsStore

    const {
      setSnackbarText = noop,
      setShowSnackbar = noop,
      setSnackbarSeverity = noop,
    } = useSnackbars()

    const post: Post = feedSourceList[index]

    const {
      id,
      personName,
      isAnonymous,
      postType,
      message,
      formattedPostDate,
      formattedPostTime,
      personID,
      isMuted,
      isPersonBanned,
      muteReason,
      isQuarantined,
      quarantineReason,
      createdAt,
      setMuteReason,
      setIsQuarantined,
    } = post

    const isManagePersonMode = selectedPerson?.person?.id === personID

    const onMuteTogglePress = () => {
      toggleMuteDialog(true)
    }

    const onReleaseTogglePress = () => {
      toggleReleasePostDialog(true)
    }

    const toggleMutePost = async (reason = "") => {
      try {
        //manually toggle muted attribute
        manuallyToggleMutePost({ postID: id })
        if (isMuted) {
          await apiAdminUnMutePost({ postId: id })
        } else {
          await apiAdminMutePost({ postId: id, muteReason: reason })
          setMuteReason(reason)
        }
      } catch (error) {
        //revert toggle if api fails
        manuallyToggleMutePost({ postID: id })
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const editMuteReason = async (reason: string) => {
      try {
        await apiAdminMutePost({ postId: id, muteReason: reason })
        setMuteReason(reason)
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const releaseQuarantinedPost = async (reason: string) => {
      try {
        await apiAdminReleaseQuarantinedPost({
          postId: id,
          reason: reason,
        })
        setIsQuarantined(false)
      } catch (error) {
        setSnackbarSeverity("error")
        setSnackbarText("Something went wrong!")
        setShowSnackbar(true)
      }
    }

    const toggleBanUser = async () => {
      //manually toggle user banned attribute
      manuallyToggleUserBannedOnPosts({ personID })
      try {
        if (isPersonBanned) {
          await apiAdminUnBanUser({ userId: personID })
        } else {
          await apiAdminBanUser({ userId: personID })
        }
      } catch (error) {
        //revert toggle if api fails
        manuallyToggleUserBannedOnPosts({ personID })
        setSnackbarSeverity("error")
        setSnackbarText(error.message)
        setShowSnackbar(true)
      }
    }

    const useAsTemplate = () => {
      newUserPost.usePostAsTemplate({
        personID,
        message,
        isAnonymous,
        postType,
        createdAt,
      })
      toggleModalPostOpen(true)
    }

    return (
      <ListItem style={style} component="div" disablePadding>
        <ListItem
          className={clsx(classes.listItem, {
            [classes.alertModeItem]: isPersonBanned || isMuted,
            [classes.quarantineModeItem]: isQuarantined,
          })}>
          <Stack spacing={1} className={classes.userInfoContainer}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={2}
              className={classes.postInfoContainer}>
              <ListItemText
                primary={personName}
                classes={{
                  root: classes.infoTextRoot,
                  primary: clsx(classes.infoText, classes.nameText, {
                    [classes.alertText]: isPersonBanned,
                  }),
                }}
                style={{ margin: 0, width: "15%", wordBreak: "break-all" }}
              />
              <Stack direction="row" spacing={2}>
                <ListItemText
                  primary={formattedPostDate}
                  classes={{
                    root: classes.infoTextRoot,
                    primary: clsx(classes.infoText, classes.dateText),
                  }}
                  style={{ margin: 0 }}
                />

                <ListItemText
                  primary={formattedPostTime}
                  classes={{
                    root: classes.infoTextRoot,
                    primary: clsx(classes.infoText, classes.dateText),
                  }}
                />
              </Stack>

              <ListItemText
                primary={`#${postType}`}
                classes={{
                  root: classes.infoTextRoot,
                  primary: clsx(classes.infoText, {
                    [classes.gratitudeTagText]: postType === "gratitude",
                    [classes.intentionTagText]: postType === "intention",
                  }),
                }}
              />
            </Stack>

            <ListItemText
              primary={message}
              classes={{ primary: classes.messageText }}
            />
          </Stack>

          <Stack direction="column" flex={1.4}>
            <Stack
              direction="row"
              spacing={2}
              justifyContent={"flex-end"}
              flexWrap={"wrap"}
              rowGap={2}>
              {isQuarantined && (
                <Button
                  startIcon={<ReleaseIcon />}
                  classes={{
                    root: classes.actionButton,
                    textPrimary: classes.actionButtonText,
                    startIcon: classes.actionButtonIcon,
                  }}
                  onClick={onReleaseTogglePress}>
                  Release Post
                </Button>
              )}

              <Button
                startIcon={<VisibilityOffOutlinedIcon />}
                classes={{
                  root: classes.actionButton,
                  textPrimary: clsx(classes.actionButtonText, {
                    [classes.alertText]: isMuted,
                  }),
                  startIcon: classes.actionButtonIcon,
                }}
                onClick={onMuteTogglePress}>
                {isMuted ? "Post Hidden" : "Hide Post"}
              </Button>

              <Button
                startIcon={<NotificationsOffOutlinedIcon />}
                classes={{
                  root: classes.actionButton,
                  textPrimary: clsx(classes.actionButtonText, {
                    [classes.alertText]: isPersonBanned,
                  }),
                  startIcon: classes.actionButtonIcon,
                }}
                onClick={toggleBanUser}>
                {isPersonBanned ? "Person Muted" : "Mute Person"}
              </Button>

              <Button
                startIcon={<PersonOutlineOutlinedIcon />}
                classes={{
                  root: classes.actionButton,
                  textPrimary: clsx(classes.actionButtonText, {
                    [classes.managePersonActive]: isManagePersonMode,
                  }),
                  startIcon: classes.actionButtonIcon,
                }}
                onClick={() => getPersonData({ personID })}>
                Manage Person
              </Button>
            </Stack>

            {isQuarantined && (
              <Stack direction="row" mt={3} justifyContent={"flex-end"}>
                <Typography className={classes.quarantineLabel}>
                  Quarantine Reason:
                </Typography>

                <Typography className={classes.quarantineValue}>
                  {quarantineReason ?? "No reason has been submited!"}
                </Typography>
              </Stack>
            )}
          </Stack>

          <ShowMorePopup useAsTemplate={useAsTemplate} />
        </ListItem>

        <MutePostDialog
          open={openMuteDialog}
          isMuted={isMuted}
          muteReason={muteReason}
          postMessage={`${personName} - "${message}"`}
          toggleMutePost={toggleMutePost}
          editMuteReason={editMuteReason}
          handleClose={() => toggleMuteDialog(false)}
        />

        <ReleasePostDialog
          open={openReleasePostDialog}
          releasePost={releaseQuarantinedPost}
          handleClose={() => toggleReleasePostDialog(false)}
        />
      </ListItem>
    )
  },
)
