import React, { MouseEvent, useState } from "react"
import { Popover, Button, Stack, Theme, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/styles"
import MoreHorizIcon from "@mui/icons-material/MoreHoriz"

import { typography } from "../../services/theme/typography"
import AddIcon from "@mui/icons-material/Add"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actionIcon: {
      alignSelf: "center",
      height: theme.spacing(3),
      width: theme.spacing(3),
      marginRight: theme.spacing(1),
      cursor: "pointer",
    },
    container: {
      width: 260,
      borderRadius: theme.spacing(2),
      marginTop: theme.spacing(0.5),
      border: "1px #C7CFD4 solid",
      padding: theme.spacing(2),
    },

    popoverTitle: {
      fontSize: theme.spacing(2),
      color: "#2D353B",
      fontFamily: typography.circularXXBold.fontFamily,
      marginBottom: theme.spacing(3),
    },
    actionButton: {
      padding: 0,
      flexDirection: "column",
      alignItems: "center",
      "&:hover": {
        backgroundColor: "transparent",
        color: "#485359",
      },
      "&:active": {
        color: "#2D353B",
      },
    },
    actionButtonText: {
      fontSize: 15,
      lineHeight: "18px",
      color: "#677278",
      fontWeight: "normal",
      "text-transform": "none",
    },
    actionButtonIcon: {
      margin: 0,
      marginBottom: theme.spacing(1),
    },
  }),
)

const ShowMorePopup = ({ useAsTemplate }) => {
  const classes = useStyles()
  const [showMoreEl, setShowMoreEl] = useState<HTMLElement | null>(null)

  const openShowMoreMenu = (event: MouseEvent<HTMLElement>) =>
    setShowMoreEl(event.currentTarget)

  const closeShowMoreMenu = () => setShowMoreEl(null)

  return (
    <Stack alignSelf={"self-start"}>
      <div onClick={openShowMoreMenu}>
        <MoreHorizIcon className={classes.actionIcon} />
      </div>

      <Popover
        id={Boolean(showMoreEl) ? "more-popover" : undefined}
        open={Boolean(showMoreEl)}
        anchorEl={showMoreEl}
        onClose={closeShowMoreMenu}
        classes={{ paper: classes.container }}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}>
        <Typography className={classes.popoverTitle}>Post Options</Typography>

        <Button
          startIcon={<AddIcon />}
          classes={{
            root: classes.actionButton,
            textPrimary: classes.actionButtonText,
            startIcon: classes.actionButtonIcon,
          }}
          onClick={useAsTemplate}>
          Use as template
        </Button>
      </Popover>
    </Stack>
  )
}

export { ShowMorePopup }
