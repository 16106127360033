import React, { useState } from "react"
import Button from "@mui/material/Button"
import TextField from "@mui/material/TextField"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

interface ReleasePostDialogProps {
  open: boolean
  handleClose: () => void
  releasePost: (reason: string) => void
}

const ReleasePostDialog = ({
  open,
  handleClose,
  releasePost,
}: ReleasePostDialogProps) => {
  const [reason, setReason] = useState("")
  const [error, setError] = useState("")

  const onChangeText = (e) => {
    Boolean(error) && setError("")
    if (e.target.value.length <= 64) {
      setReason(e.target.value)
    } else {
      setError("Maximum length is 64 characters !")
    }
  }

  const onClose = () => {
    handleClose()
    setReason("")
    setError("")
  }

  const validate = () => {
    if (reason.trim().length === 0) {
      setError("Reason is required !")
      return { valid: false }
    } else if (reason.trim().length > 64) {
      setError("Maximum length is 64 characters !")
      return { valid: false }
    }
    return { valid: true }
  }

  const submitReleasePost = () => {
    const { valid } = validate()
    if (!valid) {
      return
    }

    releasePost(reason)

    onClose()
  }

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: 24, fontWeight: 600 }}>
          Release Post
        </DialogTitle>

        <DialogContent>
          <DialogContentText fontSize={17} color="#63646D">
            To release this post please give a reason . This will be used to
            train the AI and improve moderation
          </DialogContentText>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            type="email"
            fullWidth
            variant="standard"
            required
            error={Boolean(error)}
            helperText={error}
            value={reason}
            onChange={onChangeText}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} sx={{ fontSize: 17, color: "#677278" }}>
            Cancel
          </Button>

          <Button onClick={submitReleasePost} sx={{ fontSize: 17 }}>
            Release Post
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export { ReleasePostDialog }
