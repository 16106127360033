import { useState } from "react"

import {
  Stack,
  Theme,
  IconButton,
  Tooltip,
  TooltipProps,
  tooltipClasses,
} from "@mui/material"
import { makeStyles, createStyles } from "@material-ui/styles"
import CloseIcon from "@mui/icons-material/Close"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
        fontSize: theme.spacing(1.75),
        color: theme.palette.common.white,
        padding: theme.spacing(1.5),
      },
      [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
      },
    },
    tooltipCloseButton: {
      color: theme.palette.common.white,
      padding: theme.spacing(1),
      marginTop: `-${theme.spacing(1)}`,
      marginRight: `-${theme.spacing(1)}`,
    },
  }),
)

const MetricsTooltip = ({ children, title }: TooltipProps) => {
  const classes = useStyles()

  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  return (
    <Tooltip
      open={title && open}
      onClose={handleClose}
      onOpen={handleOpen}
      title={
        <Stack direction="row" alignItems="flex-start">
          <span>{title} </span>
          <IconButton
            className={classes.tooltipCloseButton}
            onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
      }
      placement="top-start"
      arrow
      classes={{ popper: classes.tooltip }}>
      {children}
    </Tooltip>
  )
}

export { MetricsTooltip }
