import { useCallback } from "react"
import {
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
} from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { FILTER_ACTIVE } from "../../models/tri-ideas-pagination/filter-types"

interface UseTableParams {
  restorePaginationState: () => void
}

const useTable = ({ restorePaginationState }: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()

  const { triIdeasPagination } = adminMslEventStoreModel
  const {
    apiAdminGetTriIdeas,
    apiAdminGetTriIdeasSearch,
    setQuery,
    setLoading,
    resetPagination,
    setSortBy,
    setOrderBy,
    setFilterBy,
    filterBy,
  } = triIdeasPagination

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      const sortOption = sortModel[0]

      restorePaginationState()

      if (!sortOption) {
        resetPagination()

        setSortBy(null)
        setOrderBy(null)

        apiAdminGetTriIdeas()
        return
      }

      resetPagination()

      setSortBy(sortOption.field)
      setOrderBy(sortOption.sort)

      apiAdminGetTriIdeas()
    },
    [
      apiAdminGetTriIdeas,
      resetPagination,
      restorePaginationState,
      setLoading,
      setSortBy,
      setOrderBy,
    ],
  )

  const onFilterChange = useCallback(
    async (
      filterModel: GridFilterModel,
      details: GridCallbackDetails<"filter">,
    ) => {
      if (filterModel?.items?.[0]?.field === "type") {
        if (details.reason === "upsertFilterItem") {
          const filterOption = filterModel?.items?.[0]?.value
          const isEmptyFilter = !filterOption
          const isFilterReset = filterBy !== FILTER_ACTIVE
          if (isEmptyFilter) {
            if (isFilterReset) {
              setLoading(true)
              await new Promise((resolve) => setTimeout(resolve, 100))
              resetPagination()
              restorePaginationState()
              apiAdminGetTriIdeas()
            }
            return
          }

          setLoading(true)
          await new Promise((resolve) => setTimeout(resolve, 100))
          resetPagination()
          restorePaginationState()
          setFilterBy(filterOption)
          apiAdminGetTriIdeas()
        }
      }

      if (filterModel?.items?.[0]?.field === "title") {
        if (details.reason === "upsertFilterItem") {
          const value = filterModel?.items?.[0]?.value

          setLoading(true)
          resetPagination()
          restorePaginationState()

          if (value) {
            setQuery(value)

            await apiAdminGetTriIdeasSearch()

            return
          } else {
            await apiAdminGetTriIdeas()
            return
          }
        }
      }
    },
    [
      resetPagination,
      restorePaginationState,
      setLoading,
      setFilterBy,
      apiAdminGetTriIdeas,
      apiAdminGetTriIdeasSearch,
      filterBy,
      setQuery,
    ],
  )

  return {
    handleSortModelChange,
    onFilterChange,
  }
}

export { useTable }
