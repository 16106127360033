import React from "react"
import { Stack, Typography, Theme } from "@material-ui/core"

import makeStyles from "@material-ui/styles/makeStyles"

import { typography } from "../../services/theme/typography"

const useStyles = makeStyles<Theme>((theme) => ({
  fieldLabel: {
    fontSize: theme.spacing(2),
    color: "#485359",
    display: "flex",
    marginBottom: theme.spacing(1),
    ...typography.circularXXMedium,
    "&>span": {
      color: theme.palette.error.main,
      marginLeft: theme.spacing(0.5),
    },
  },
  label: {
    fontSize: theme.spacing(2),
    color: "#485359",
    width: theme.spacing(6),
    marginBottom: theme.spacing(0.5),
    ...typography.circularXXMedium,
  },
  value: {},

  howToContainer: {
    backgroundColor: "#e5e5e5",
    padding: theme.spacing(0.5, 1),
    marginBottom: theme.spacing(1),
  },

  emptyState: {
    textAlign: "center",
    ...typography.circularXXMedium,
  },
}))

const AssociatedHowTos = ({ howTos }) => {
  const classes = useStyles()

  return (
    <Stack mt={2}>
      <Typography className={classes.fieldLabel}>Associated How-Tos</Typography>

      {howTos.length ? (
        <Stack>
          {howTos.map(({ id, title }) => {
            return (
              <Stack className={classes.howToContainer}>
                <Stack flexDirection={"row"}>
                  <Typography className={classes.label}>ID:</Typography>

                  <Typography className={classes.value}>{id}</Typography>
                </Stack>

                <Stack flexDirection={"row"}>
                  <Typography className={classes.label}>Title:</Typography>

                  <Typography className={classes.value}>{title}</Typography>
                </Stack>
              </Stack>
            )
          })}
        </Stack>
      ) : (
        <Typography className={classes.emptyState}>
          No how-tos are associated with this product.
        </Typography>
      )}
    </Stack>
  )
}

export { AssociatedHowTos }
