import { useCallback } from "react"
import {
  GridSortModel,
  GridFilterModel,
  GridCallbackDetails,
} from "@mui/x-data-grid"

import { useStores } from "../../models/root-store"

interface UseTableParams {
  fetchCategories: () => Promise<void>
  restorePaginationState: () => void
}

const useTable = ({
  fetchCategories,
  restorePaginationState,
}: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()
  const { triCategoriesPagination } = adminMslEventStoreModel
  const {
    query,
    apiAdminGetTriCategories,
    apiAdminSearchTriCategories,
    setLoading,
    resetTriCategories,
    resetPagination,
    setSortBy,
    setQuery,
    setNext,
    setPage,
    setCount,
    setTotalCount,
  } = triCategoriesPagination

  const handleSortModelChange = useCallback(
    async (sortModel: GridSortModel) => {
      setLoading(true)
      await new Promise((resolve) => setTimeout(resolve, 100))

      restorePaginationState()
      const sortOption = sortModel[0]

      const handleSort = async () => {
        if (query.trim().length) {
          setCount(0)
          setNext(null)
          setPage(1)

          if (sortOption) {
            setSortBy(sortOption.field)
          }

          resetTriCategories()
          await apiAdminSearchTriCategories({ query })
        } else {
          resetPagination()

          if (sortOption) {
            setSortBy(sortOption.field)
          }

          fetchCategories()
        }
      }

      await handleSort()
    },
    [
      query,
      setNext,
      setPage,
      setCount,
      setLoading,
      setSortBy,
      fetchCategories,
      apiAdminSearchTriCategories,
      resetPagination,
      resetTriCategories,
      restorePaginationState,
    ],
  )

  const handleFilterModelChange = useCallback(
    async (
      filterModel: GridFilterModel,
      details: GridCallbackDetails<"filter">,
    ) => {
      const value = filterModel?.items?.[0]?.value

      if (details.reason === "upsertFilterItem") {
        setLoading(true)

        setNext(0)
        setCount(0)
        setTotalCount(0)
        resetTriCategories()

        restorePaginationState()

        if (value) {
          setQuery(value)

          await apiAdminSearchTriCategories({ query: value })

          return
        } else {
          setQuery("")
          await apiAdminGetTriCategories()
          return
        }
      }
    },
    [
      setLoading,
      setQuery,
      setNext,
      setTotalCount,
      setCount,
      apiAdminGetTriCategories,
      apiAdminSearchTriCategories,
      restorePaginationState,
      resetTriCategories,
    ],
  )

  return {
    handleSortModelChange,
    handleFilterModelChange,
  }
}

export { useTable }
