import { useCallback } from "react"
import { GridFilterModel, GridCallbackDetails } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"

interface UseTableParams {
  restorePaginationState: () => void
}

const useTable = ({ restorePaginationState }: UseTableParams) => {
  const { adminMslEventStoreModel } = useStores()

  const {
    triProductsPagination,
    triIdeaProductsPagination,
  } = adminMslEventStoreModel

  const handleFilterModelChange = useCallback(
    async (
      filterModel: GridFilterModel,
      details: GridCallbackDetails<"filter">,
    ) => {
      const { setQuery, apiAdminGetTriIdeaProducts } = triIdeaProductsPagination

      const value = filterModel?.items?.[0]?.value

      if (details.reason === "upsertFilterItem") {
        triIdeaProductsPagination.setLoading(true)
        triProductsPagination.setLoading(true)

        triIdeaProductsPagination.resetTriProducts()
        triIdeaProductsPagination.setNext(0)
        triIdeaProductsPagination.setPage(1)

        triProductsPagination.resetTriProducts()
        triProductsPagination.setPage(1)

        restorePaginationState()

        if (value) {
          setQuery(value)

          await apiAdminGetTriIdeaProducts()

          return
        } else {
          triIdeaProductsPagination.setQuery("")

          await triProductsPagination.apiAdminGetTriProducts()
          return
        }
      }
    },
    [restorePaginationState, triIdeaProductsPagination, triProductsPagination],
  )

  return {
    handleFilterModelChange,
  }
}

export { useTable }
