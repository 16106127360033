import moment, { Moment } from "moment"
import { DateRange } from "@mui/lab/DateRangePicker"

import { Post } from "../post"
import { AdminUserSearch } from "../admin-user-search"
import {
  DESCENDING,
  SORT_TYPE,
  POST_TYPE_FILTER,
  PAST,
  FUTURE,
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  CUSTOM_RANGE,
  DATE_RANGE_FILTER,
  SEEDED,
  NON_SEEDED,
  QUARANTINED,
  GRATITUDE,
  INTENTION,
  HIDDEN,
} from "./filter-types"

export const filterByPostType = (
  postsList: Post[],
  postTypeFilter: POST_TYPE_FILTER,
) => {
  if (postTypeFilter === GRATITUDE || postTypeFilter === INTENTION) {
    return postsList.filter((post) => post.postType === postTypeFilter)
  }

  if (postTypeFilter === SEEDED) {
    return postsList.filter((post) => post.wasSeeded)
  }

  if (postTypeFilter === NON_SEEDED) {
    return postsList.filter((post) => !post.wasSeeded)
  }

  if (postTypeFilter === HIDDEN) {
    return postsList.filter((post) => post.isHidden)
  }

  if (postTypeFilter === QUARANTINED) {
    return postsList.filter((post) => post.isQuarantined)
  }

  return postsList
}

/**
 * Helper function to check if a given date is between a range
 * Moment's method `isBetween` does check if the date is between a given rage, but it doesn't include dhe end point
 * So we check if the given date in on the start or end point just in case
 */
type CustomDate = Moment | string | number
export const checkIfInRange = (
  dateToCheck: CustomDate,
  startDate: CustomDate,
  endDate: CustomDate,
) => {
  const datePoint = moment(dateToCheck)
  const startPoint = moment(startDate)
  const endPoint = moment(endDate)
  return (
    startPoint.isSame(datePoint, "day") ||
    endPoint.isSame(datePoint, "day") ||
    datePoint.isBetween(startPoint, endPoint)
  )
}

export const filterByDateRange = (
  postsList: Post[],
  dateRangeFilter: DATE_RANGE_FILTER,
  dateRangePoints: number[],
) => {
  return postsList.filter((post) => {
    const postDate = moment(post.createdAt)
    const now = moment()
    switch (dateRangeFilter) {
      //check if the post date is in the future
      case FUTURE:
        return postDate.diff(now) > 0
      //check if the post date is in the past
      case PAST:
        return postDate.diff(now) <= 0
      //check if the post date is the same with today date
      case TODAY:
        return postDate.isSame(now, "day")

      //check if the post date is the same with yesterday date
      case YESTERDAY:
        const yesterday = moment().subtract(1, "days")
        return postDate.isSame(yesterday, "day")

      //check if the post is between now and the day 7 days from now
      case LAST_7_DAYS:
        const lastWeekDay = moment().subtract(7, "days")
        return checkIfInRange(postDate, lastWeekDay, now)

      //check if the post is between now and the day 30 days from now
      case LAST_30_DAYS:
        const lastMonthDay = moment().subtract(30, "days")
        return checkIfInRange(postDate, lastMonthDay, now)

      //check if the post is between custom range
      //if the range is not set, the filtering is off
      case CUSTOM_RANGE:
        const isRangeSelected = dateRangePoints.every((point) => Boolean(point))
        if (!isRangeSelected) {
          return true
        }
        return checkIfInRange(postDate, dateRangePoints[0], dateRangePoints[1])

      //TODO: Custom range filter
      default:
        return true
    }
  })
}

export const sortPosts = (postsList: Post[], sortBy: SORT_TYPE) => {
  return postsList.sort((a, b) => {
    const first = moment(a.createdAt).unix() * 1000
    const second = moment(b.createdAt).unix() * 1000

    if (sortBy === DESCENDING) {
      return second - first
    }

    return first - second
  })
}

/**
 * Helper method which iterates through a posts list and toggles mute attribute of the post with the given `postID`
 */
export const findAndToggleMutePost = (postsList: Post[], postID: string) => {
  postsList.find((post) => post.id === postID)?.toggleMuted?.()
}

/**
 * Helper method which iterates through a posts list and toggles user ban attribute of the post with the given `personID`
 */
export const findAndToggleUserBanOnPosts = (
  postsList: Post[],
  personID: string,
) => {
  postsList.forEach((post) => {
    if (post.personID === personID) {
      post.toggleUserBanned()
    }
  })
}

/**
 * Helper method which iterates through a users list and toggles user ban attribute of the user with the given `personID`
 * Also uses `findAndToggleUserBanOnPosts` helper method for toggling the ban attribute for the posts of that user
 */
export const findAndToggleUserBanOnUsers = (
  usersList: AdminUserSearch[],
  personID: string,
) => {
  const selectedUser = usersList.find((user) => user.personID === personID)
  if (!selectedUser) {
    return
  }
  selectedUser.toggleUserBan()
}

/**
 * Method for getting the timestamp of the last post date, which will be used for feed pagination
 */
export const getLastPostTimestamp = (postsList: Post[]): string | null => {
  if (!postsList?.length) {
    return null
  }

  const lastPost = postsList[postsList.length - 1]
  if (!lastPost?.createdAt) {
    return null
  }

  return moment(lastPost.createdAt).format("X")
}

/**
 * Method for removing existing posts from the new feed posts list
 */

export const filterNewPostsDuplicates = (
  existingPosts: Post[],
  newPosts: Post[],
): Post[] => {
  const newPostsFormatted = newPosts.filter(
    (post) => !existingPosts.find((item) => post.id === item.id),
  )

  return newPostsFormatted
}

/**
 * Method which checks if there are more older posts to paginate
 * It gets the difference from the last post date with the minimum date
 * If the difference is >= 0, it means that there could be other posts within the minimum date
 * If the difference is < 0, it means that there are no other posts within the minimum date
 */
export const hasMoreOlderPosts = (
  postsList: Post[],
  minimumDate: CustomDate,
) => {
  if (!postsList.length) {
    return false
  }
  const lastListPost = [...postsList].pop()
  const diff = moment(lastListPost.createdAt).diff(moment(minimumDate), "days")

  return diff >= 0
}

export const getDateRangeLimitByFilterType = ({
  filter,
  customRangeValue,
}: {
  filter: DATE_RANGE_FILTER
  customRangeValue: DateRange<Date>
}) => {
  switch (filter) {
    case FUTURE:
      return [moment(), 0]
    case PAST:
      return [0, moment()]
    case TODAY:
      return [moment().startOf("day"), moment().endOf("day")]
    case YESTERDAY:
      return [
        moment().subtract(1, "days").startOf("day"),
        moment().subtract(1, "days").endOf("day"),
      ]
    case LAST_7_DAYS:
      return [moment().subtract(7, "days").endOf("day"), moment()]
    case LAST_30_DAYS:
      return [moment().subtract(30, "days").endOf("day"), moment()]
    case CUSTOM_RANGE:
      const dateRange = [
        moment(customRangeValue[0]).startOf("day"),
        moment(customRangeValue[1] || customRangeValue[0]).endOf("day"),
      ]
      return dateRange

    default:
      return [0, moment()]
  }
}
