import { types, Instance, SnapshotOut, cast, clone } from "mobx-state-tree"
import moment from "moment"
import { withEnvironment, withRootStore } from "../extensions"
import { Post, PostModel } from "../post"
import { ProgramInvitation } from "../program-invitation"
import { AdminUserSearchModel, AdminUserSearch } from "../admin-user-search"
import {
  FullPersonDetails,
  FullPersonDetailsModel,
} from "../admin-user-search/full-person-details"
import {
  EnrolledProgramsModel,
  EnrolledPrograms,
} from "../admin-user-search/enrolled-programs"
import { VerificationCode, VerificationCodeModel } from "../verification-code"
import { ClientErrorResponse } from "../../services/api/api-problem"
import {
  ASCENDING,
  DESCENDING,
  SORT_TYPE,
  EMPTY_FILTER,
  GRATITUDE,
  INTENTION,
  POST_TYPE_FILTER,
  FUTURE,
  PAST,
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  CUSTOM_RANGE,
  DATE_RANGE_FILTER,
  SHOW_SEEDED,
  HIDE_SEEDED,
  SEEDED_POST_FILTER,
  QUARANTINED,
  SEEDED,
  HIDDEN,
  NON_SEEDED,
} from "./filter-types"
import {
  filterByPostType,
  filterByDateRange,
  sortPosts,
  findAndToggleMutePost,
  findAndToggleUserBanOnPosts,
  findAndToggleUserBanOnUsers,
  getLastPostTimestamp,
  filterNewPostsDuplicates,
  checkIfInRange,
} from "./utils"

const VERSION = 1
const FEED_POSTS_LIMIT = 50

export const AdminToolsStoreModel = types
  .model("AdminToolsStore")
  .extend(withEnvironment)
  .extend(withRootStore)
  .props({
    version: VERSION,
    feedPosts: types.optional(types.array(PostModel), []),
    feedPostsLimit: FEED_POSTS_LIMIT,
    //pagination flag for feed list, which will be the timestamp of the last post date from the feed list
    endPostTimestamp: types.maybeNull(types.string),
    newUserPost: types.optional(PostModel, () =>
      PostModel.create({
        id: "-1",
      }),
    ),

    //feed posts for custom date range filter
    customDateRangePosts: types.optional(types.array(PostModel), []),
    //pagination flag for custom date range posts
    endCustomDateRangePostTimestamp: types.maybeNull(types.string),

    //user search results list
    userSearchList: types.optional(types.array(AdminUserSearchModel), []),
    //search results flag, used for deciding which should be the main source of feed posts
    //user search api loading flag
    loadingSearchUser: false,

    showSearchPostResults: false,
    searchPostResults: types.optional(types.array(PostModel), []),
    //posts of a user search api loading flag
    loadingSearchPostResults: false,

    //sort and filters flags
    sortType: types.optional(
      types.union(types.literal(ASCENDING), types.literal(DESCENDING)),
      DESCENDING,
    ),
    postTypeFilter: types.optional(
      types.union(
        types.literal(EMPTY_FILTER),
        types.literal(GRATITUDE),
        types.literal(INTENTION),
        types.literal(SEEDED),
        types.literal(NON_SEEDED),
        types.literal(HIDDEN),
        types.literal(QUARANTINED),
      ),
      EMPTY_FILTER,
    ),
    seededPostFilter: types.optional(
      types.union(
        types.literal(EMPTY_FILTER),
        types.literal(SHOW_SEEDED),
        types.literal(HIDE_SEEDED),
      ),
      EMPTY_FILTER,
    ),
    dateRangeFilter: types.optional(
      types.union(
        types.literal(EMPTY_FILTER),
        types.literal(FUTURE),
        types.literal(PAST),
        types.literal(TODAY),
        types.literal(YESTERDAY),
        types.literal(LAST_7_DAYS),
        types.literal(LAST_30_DAYS),
        types.literal(CUSTOM_RANGE),
      ),
      EMPTY_FILTER,
    ),
    //date range start and end point values, used for filtering posts between dates
    dateRangePoints: types.array(types.number),

    //selected person to manage
    selectedPerson: types.optional(
      types.union(FullPersonDetailsModel, types.null),
      null,
    ),
    loadingSelectedPerson: false,
    //show all posts from the selected person flag
    showSelectedPersonPosts: false,

    programsList: types.optional(types.array(EnrolledProgramsModel), []),
    verificationCodes: types.optional(types.array(VerificationCodeModel), []),
    isPostModalOpen: types.maybeNull(types.boolean),
  })
  .actions((self) => ({
    setFeedPosts(value: Post[]) {
      self.feedPosts.replace(value)
    },
    setEndPostTimestamp(value: string | null) {
      self.endPostTimestamp = value
    },
    setNewUserPost(value: Post) {
      self.newUserPost = clone(value)
    },
    resetFeedPosts() {
      self.feedPosts.clear()
      self.customDateRangePosts.clear()
    },
    setUserSearchList(value: AdminUserSearch[]) {
      self.userSearchList.replace(value)
    },
    resetUserSearchList() {
      self.userSearchList.clear()
    },

    setCustomDateRangePosts(value: Post[]) {
      self.customDateRangePosts.replace(value)
    },
    setEndCustomDateRangePostTimestamp(value: string | null) {
      self.endCustomDateRangePostTimestamp = value
    },
    resetCustomDateRangePosts() {
      self.customDateRangePosts.clear()
    },

    setShowSearchPostResults(value: boolean) {
      self.showSearchPostResults = value
    },
    toggleLoadingSearchUser(value: boolean) {
      self.loadingSearchUser = value
    },

    setSearchPostResults(value: Post[]) {
      self.searchPostResults.replace(value)
    },
    toggleLoadingSearchPostResults(value: boolean) {
      self.loadingSearchPostResults = value
    },
    resetSearchPostResults() {
      self.searchPostResults.clear()
    },
    resetNewUserPost() {
      self.newUserPost.reset()
    },

    setSortType(value: SORT_TYPE) {
      self.sortType = value
    },
    setPostTypeFilter(value: POST_TYPE_FILTER) {
      self.postTypeFilter = value
    },
    setSeededPostFilter(value: SEEDED_POST_FILTER) {
      self.seededPostFilter = value
    },
    setDateRangeFilter(value: DATE_RANGE_FILTER) {
      self.dateRangeFilter = value
    },
    resetDateRangeFilter() {
      self.dateRangeFilter = EMPTY_FILTER
    },

    setDateRangePoints(value: number[]) {
      self.dateRangePoints = cast(value)
    },

    setSelectedPerson(value: FullPersonDetails) {
      self.selectedPerson = value
      //reset `showSelectedPersonPosts` when selecting a new person to manage
      self.showSelectedPersonPosts = false
    },
    resetSelectedPerson() {
      self.selectedPerson = null
      //reset `showSelectedPersonPosts` when resetting `selectedPerson`
      self.showSelectedPersonPosts = false
    },
    toggleLoadingSelectedPerson(value: boolean) {
      self.loadingSelectedPerson = value
    },
    toggleShowSelectedPersonPosts(value: boolean) {
      self.showSelectedPersonPosts = value
    },

    setProgramsList(value: EnrolledPrograms[]) {
      self.programsList.replace(value)
    },
    setVerificationCodes(value: VerificationCode[]) {
      self.verificationCodes.replace(value)
    },
    resetVerificationCodesList() {
      self.verificationCodes.clear()
    },

    toggleModalPostOpen(value: boolean) {
      self.isPostModalOpen = value
    },

    reset() {
      self.feedPosts.clear()
    },
  }))
  .actions((self) => ({
    async apiGetInitialFeedPosts(): Promise<void> {
      const { api } = self.environment
      self.resetFeedPosts()
      const { data, kind } = await api.getFeedPosts({
        limit: self.feedPostsLimit,
        ...(self.postTypeFilter !== EMPTY_FILTER && {
          filterBy: self.postTypeFilter,
        }),
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      self.setFeedPosts(data as Post[])
      self.setEndPostTimestamp(getLastPostTimestamp(data as Post[]))
    },
    async apiLoadMoreFeedPosts(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getFeedPosts({
        limit: self.feedPostsLimit,
        timestamp: self.endPostTimestamp,
        ...(self.postTypeFilter !== EMPTY_FILTER && {
          filterBy: self.postTypeFilter,
        }),
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const newFeedPosts = filterNewPostsDuplicates(
        self.feedPosts,
        data as Post[],
      )
      self.setFeedPosts(self.feedPosts.concat(newFeedPosts))
      self.setEndPostTimestamp(getLastPostTimestamp(newFeedPosts))
    },
    async apiLoadCustomDateRangePosts(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.getFeedPosts({
        limit: self.feedPostsLimit,
        timestamp: self.endCustomDateRangePostTimestamp,
        ...(self.postTypeFilter !== EMPTY_FILTER && {
          filterBy: self.postTypeFilter,
        }),
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      const newFeedPosts = filterNewPostsDuplicates(
        self.customDateRangePosts,
        data as Post[],
      )
      //check if there are more posts to paginate from the custom range using the start date of the range
      const hasMorePosts =
        data.length &&
        checkIfInRange(
          moment(data[0].createdAt),
          self.dateRangePoints[0],
          self.dateRangePoints[1],
        )
      //if there are more posts, use the last post from list as pagination
      const nextEndTimestamp = hasMorePosts
        ? getLastPostTimestamp(newFeedPosts)
        : null

      self.setCustomDateRangePosts(
        self.customDateRangePosts.concat(newFeedPosts),
      )
      self.setEndCustomDateRangePostTimestamp(nextEndTimestamp)
    },

    async apiAdminGetPersonDetails(
      personId: string,
    ): Promise<FullPersonDetails> {
      const { api } = self.environment
      const { kind, data } = await api.adminGetPersonDetails({ personId })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      return data as FullPersonDetails
    },
  }))
  .actions((self) => ({
    async apiAdminReleaseQuarantinedPost({
      postId,
      reason,
    }: {
      postId: string
      quarantineResolution?: string
      reason: string
    }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminReleaseQuarantinedPost({
        postId,
        quarantineReleaseNote: reason,
        quarantineResolution: "released",
        isQuarantined: false,
      })
      if (kind !== "ok") {
        const reason = typeof data === "string" ? data : data?.reason
        throw new Error(reason)
      }
    },
    async apiAdminMutePost({
      postId,
      muteReason,
    }: {
      postId: string
      muteReason: string
    }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminMutePost({ postId, muteReason })
      if (kind !== "ok") {
        const reason = typeof data === "string" ? data : data?.reason
        throw new Error(reason)
      }
    },
    async apiAdminUnMutePost({ postId }: { postId: string }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminUnMutePost({ postId })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminCreatePost(): Promise<void> {
      const { api } = self.environment

      const {
        personID,
        message,
        isAnonymous,
        postType,
        createdAt,
      } = self.newUserPost

      const { kind, data } = await api.adminCreatePost({
        personID,
        message,
        isAnonymous,
        postType,
        createdAt,
      })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }

      self.apiGetInitialFeedPosts()
    },

    async apiAdminBanUser({ userId }: { userId: string }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminBanUser({ userId })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminUnBanUser({ userId }: { userId: string }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminUnBanUser({ userId })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminSearchUser({ query }: { query: string }): Promise<void> {
      const { api } = self.environment

      //activate loading flag
      self.toggleLoadingSearchUser(true)

      const { kind, data } = await api.adminSearchUser({ query })
      if (kind !== "ok") {
        //if api fails, disable loading flag and reset `userSearchList`
        self.toggleLoadingSearchUser(false)
        self.resetUserSearchList()
        const error = data as ClientErrorResponse
        const errorMessage =
          error?.reason === "ERROR_SEARCH_EMPTY"
            ? "No results found"
            : error?.reason
        throw new Error(errorMessage)
      }

      //if api success, disable loading flag and set the newest `userSearchList`
      self.toggleLoadingSearchUser(false)
      self.setUserSearchList(data as AdminUserSearch[])
    },
    async apiAdminSearchPost({ query }: { query: string }): Promise<void> {
      const { api } = self.environment

      //activate loading flag
      self.toggleLoadingSearchPostResults(true)

      //api post search
      const { kind, data } = await api.adminSearchPost({ query })
      if (kind !== "ok") {
        self.toggleLoadingSearchPostResults(false)
        const error = data as ClientErrorResponse
        const errorMessage =
          error?.reason === "ERROR_SEARCH_EMPTY"
            ? "No results found"
            : error?.reason
        throw new Error(errorMessage)
      }

      self.setSearchPostResults(data as Post[])
      self.toggleLoadingSearchPostResults(false)
    },
    async apiAdminDeleteUser({ userId }: { userId: string }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminDeleteUser({ userId })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminUnsubscribeEmail({
      email,
    }: {
      email: string
    }): Promise<void> {
      const { api } = self.environment

      const { kind, data } = await api.adminUnsubscribeEmail({ email })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminGetPersonInvitations({
      email,
    }: {
      email: string
    }): Promise<ProgramInvitation[]> {
      const { api } = self.environment
      const { data, kind } = await api.adminGetUserInvitations({
        email,
      })
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }

      return data as ProgramInvitation[]
    },
    async apiAdminDeletePersonInvitations({
      email,
    }: {
      email: string
    }): Promise<void> {
      const { api } = self.environment
      const { kind, data } = await api.adminDeleteUserInvitations({
        email,
      })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminGetAllPrograms(): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminGetAllPrograms()
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setProgramsList(data.data as EnrolledPrograms[])
    },
    async apiAdminChangePersonProgram({
      personId,
      programId,
    }: {
      personId: string
      programId: string
    }): Promise<void> {
      const { api } = self.environment
      const { kind, data } = await api.adminChangePersonProgram({
        programId,
        personId,
      })
      if (kind !== "ok") {
        throw new Error(data?.reason)
      }
    },
    async apiAdminGetPersonVerificationCodes(): Promise<void> {
      const { api } = self.environment
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (!Boolean(selectedPerson)) {
        return
      }
      try {
        const { kind, data } = await api.adminGetPersonVerificationCodes({
          personID: selectedPerson.person.id,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        selectedPerson.setVerificationCodes(data as VerificationCode[])
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiAdminUpdatePerson(params: {
      firstName?: string
      lastName?: string
      email?: string
      mobilePhone?: string
    }): Promise<void> {
      const { api } = self.environment
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (!Boolean(selectedPerson)) {
        return
      }
      const {
        id,
        firstName,
        lastName,
        email,
        mobilePhone,
        countryCode,
        zipCode,
      } = selectedPerson.person

      try {
        const { kind, data } = await api.adminUpdatePerson({
          firstName,
          lastName,
          email,
          mobilePhone,
          countryCode,
          zipCode,
          personID: id,
          ...params,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        selectedPerson.setPerson(data)
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiAdminUpdatePersonProfile(params: {
      smsAllowed?: boolean
    }): Promise<void> {
      const { api } = self.environment
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (!Boolean(selectedPerson)) {
        return
      }
      try {
        const { kind, data } = await api.adminUpdatePersonProfile({
          personID: selectedPerson.person.id,
          profileID: selectedPerson.person.profile.id,
          ...params,
        })

        if (kind !== "ok") {
          //@ts-ignore
          throw new Error(data?.reason)
        }

        selectedPerson.person.setProfile(data)
      } catch (error) {
        throw new Error(error)
      }
    },
    async apiAdminGetVerificationCodes(params: {
      email: string
    }): Promise<void> {
      const { api } = self.environment
      const { data, kind } = await api.adminGetVerificationCodes(params)
      if (kind !== "ok") {
        //@ts-ignore
        throw new Error(data?.reason)
      }
      self.setVerificationCodes(data)
      if (!data || data.length === 0) {
        throw new Error("No verification codes found!")
      }
    },
  }))
  .actions((self) => ({
    async getPersonData({ personID }: { personID: string }): Promise<void> {
      //activate loading flag
      self.toggleLoadingSelectedPerson(true)

      try {
        const data = await self.apiAdminGetPersonDetails(personID)
        const selectedPersonData = data as FullPersonDetails

        //if api success, disable loading flag and set the newest `selectedPerson`
        self.setSelectedPerson(selectedPersonData)
        self.toggleLoadingSelectedPerson(false)
      } catch (error) {
        self.toggleLoadingSelectedPerson(false)
        const errorMessage =
          error?.reason === "ERROR_SEARCH_EMPTY"
            ? "Couldn't fetch person data"
            : error?.reason
        throw new Error(errorMessage)
      }
    },
    async apiAdminGetSelectedPersonInvitations(): Promise<void> {
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (!Boolean(selectedPerson)) {
        return
      }
      selectedPerson.toggleLoadingProgramInvitations(true)
      try {
        const data = await self.apiAdminGetPersonInvitations({
          email: selectedPerson.person.email,
        })
        selectedPerson.setProgramInvitations(data as ProgramInvitation[])
        selectedPerson.toggleLoadingProgramInvitations(false)
      } catch (error) {
        selectedPerson.toggleLoadingProgramInvitations(false)
        throw new Error(error)
      }
    },
    /**
     * Method for syncing mute attribute for all posts in the store
     * Iterate through all the posts lists on the store, and find the post with the given id
     * Manually toggle muted attribute for that post
     */
    manuallyToggleMutePost({ postID }: { postID: string }) {
      //iterate through feed posts list to find the post with the given id
      findAndToggleMutePost(self.feedPosts, postID)

      //iterate through `searchPostResults` list to find the post with the given id
      findAndToggleMutePost(self.searchPostResults, postID)
      findAndToggleMutePost(self.customDateRangePosts, postID)

      //check if `selectedPerson` is active and iterate that person posts list to find the post with the given id
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (Boolean(selectedPerson)) {
        findAndToggleMutePost(selectedPerson.totalUserPosts, postID)
      }
    },
    /**
     * Method for syncing user ban attribute for all users and all posts in the store
     * Iterate through all users and all posts lists on the store, and find the user/post with the given person id
     * Manually toggle muted attribute for that user/post
     */
    manuallyToggleUserBannedOnPosts({ personID }: { personID: string }) {
      //iterate through feed posts list to find the post with the given person id
      findAndToggleUserBanOnPosts(self.feedPosts, personID)
      findAndToggleUserBanOnPosts(self.searchPostResults, personID)
      findAndToggleUserBanOnPosts(self.customDateRangePosts, personID)

      //iterate through `userSearchList` list to find the user with the given id, and to change the posts of that user as well
      findAndToggleUserBanOnUsers(self.userSearchList, personID)

      //check if `selectedPerson` is active and toggle ban attribute for that user and for his posts
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (Boolean(selectedPerson)) {
        selectedPerson?.person.profile.toggleUserBan()
        findAndToggleUserBanOnPosts(selectedPerson.totalUserPosts, personID)
      }
    },
  }))
  .actions((self) => ({
    //method for changing the program of the selected person
    async changeSelectedPersonProgram({
      programId,
    }: {
      programId: string
    }): Promise<void> {
      const selectedPerson = self.selectedPerson as FullPersonDetails
      if (!Boolean(selectedPerson)) {
        return
      }
      try {
        //update the program of the selected person
        await self.apiAdminChangePersonProgram({
          personId: selectedPerson.person.id,
          programId,
        })
        //call `getPersonData` method to reload selected person data
        self.getPersonData({ personID: selectedPerson.person.id })
      } catch (error) {
        throw error
      }
    },
  }))
  .views((self) => ({
    /**
     * Derived value for getting the feed source list
     */
    get feedSourceList(): Post[] {
      //list source

      //default list is all persons posts list
      let finalPostsLists: Post[] = self.feedPosts

      //if `showSelectedPersonPosts` flag is active, show all the posts of the selected person
      if (Boolean(self.showSelectedPersonPosts)) {
        finalPostsLists = (self.selectedPerson as FullPersonDetails)
          .totalUserPosts
      } //if  `showSearchPostResults` user search post flag is activated, show search post results
      else if (self.showSearchPostResults && self.searchPostResults) {
        finalPostsLists = self.searchPostResults
      } //if custom date range filter is set, show custom date range posts from api
      else if (self.dateRangeFilter !== EMPTY_FILTER) {
        finalPostsLists = self.customDateRangePosts
      }

      // first posts filter by post type filter (only if filter is not empty)
      if (self.postTypeFilter !== EMPTY_FILTER) {
        finalPostsLists = filterByPostType(finalPostsLists, self.postTypeFilter)
      }

      // second posts filter by date range filter (only if filter is not empty)
      if (self.dateRangeFilter !== EMPTY_FILTER) {
        finalPostsLists = filterByDateRange(
          finalPostsLists,
          self.dateRangeFilter,
          self.dateRangePoints,
        )
      }

      //final sort of posts by sort type
      finalPostsLists = sortPosts(finalPostsLists.slice(), self.sortType)

      return finalPostsLists
    },
    /**
     * Derived value for getting the label of the date range picker
     * If the range is set, format start date and end date as label
     */
    get rangePointsLabel() {
      const isRangeSet = self.dateRangePoints.every((point) => Boolean(point))
      if (isRangeSet) {
        return `${moment(self.dateRangePoints[0]).format(
          "MMM Do YYYY",
        )} - ${moment(self.dateRangePoints[1]).format("MMM Do YYYY")}`
      }

      return "Custom Date Range"
    },

    //conditional pagination method
    get loadMorePosts() {
      if (self.dateRangeFilter !== EMPTY_FILTER) {
        return self.apiLoadCustomDateRangePosts
      } else {
        return self.apiLoadMoreFeedPosts
      }
    },

    //conditional hasNextPage flag
    get hasNextPage() {
      //if we are showing posts from selected person, disable pagiantion
      if (self.showSelectedPersonPosts) {
        return false
      }

      //if we are showing posts from search post results, disable pagiantion
      if (self.showSearchPostResults && self.searchPostResults) {
        return false
      }

      const mainFeedNextPage = Boolean(self.endPostTimestamp)
      const customDateRangeFeedNextPage = Boolean(
        self.endCustomDateRangePostTimestamp,
      )

      switch (self.dateRangeFilter) {
        //if there is no date filter, use feed posts pagination flag
        case EMPTY_FILTER:
          return mainFeedNextPage

        // //if there is a custom date filter, use custom date range posts pagination flag
        // case CUSTOM_RANGE:
        //   return customDateRangeFeedNextPage

        // //for the others date filters, check if there are more posts to paginate from the main feed posts
        // //since the feed posts list is sorted descending by default, we can use the main feed posts list as source
        // case PAST:
        //   return mainFeedNextPage

        // case FUTURE:
        //   return false

        // case TODAY:
        //   const now = moment()
        //   return hasMoreOlderPosts(self.feedPosts, now) && mainFeedNextPage

        // case YESTERDAY:
        //   const yesterday = moment().subtract(1, "days")
        //   return (
        //     hasMoreOlderPosts(self.feedPosts, yesterday) && mainFeedNextPage
        //   )

        // case LAST_7_DAYS:
        //   const lastWeekDay = moment().subtract(7, "days")
        //   return (
        //     hasMoreOlderPosts(self.feedPosts, lastWeekDay) && mainFeedNextPage
        //   )

        // case LAST_30_DAYS:
        //   const lastMonthDay = moment().subtract(30, "days")
        //   return (
        //     hasMoreOlderPosts(self.feedPosts, lastMonthDay) && mainFeedNextPage
        //   )

        default:
          return customDateRangeFeedNextPage
      }
    },
  }))

export interface AdminToolsStore
  extends Instance<typeof AdminToolsStoreModel> {}
export interface AdminToolsStoreSnapshot
  extends SnapshotOut<typeof AdminToolsStoreModel> {}
