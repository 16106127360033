import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import TableBody from "@material-ui/core/TableBody"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import { Box, Theme, CircularProgress, Stack } from "@material-ui/core"

import { useStores } from "../../models/root-store"
import { LogsTable, HeaderCell, BodyCell } from "../logs-table"
import { EmptyState } from "../empty-state"
import { AdminTableHeader } from "../admin-table-header"
import { CustomTooltip, ShortText } from "../admin-msl-events-list/short-text"
import { SeriesActions } from "./series-actions"
import { SeriesSwitcher } from "./switcher-action"
import { CreateSeriesModal } from "./create-series-modal"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableTrainerCircleImage: {
      width: "100%",
      maxWidth: 120,
    },
  }),
)

const AdminSeriesList = observer(() => {
  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [isCreateSeriesModalOpen, toggleCreateSeriesModalOpen] = useState(false)

  const { adminMslEventStoreModel } = useStores()
  const {
    series,
    apiAdminGetSeries,
    apiAdminGetTags,
    resetNewSeries,
  } = adminMslEventStoreModel

  useEffect(() => {
    const initialFetch = async () => {
      try {
        await apiAdminGetSeries()
        await apiAdminGetTags()
      } catch (e) {
      } finally {
        toggleFirstLoading(false)
      }
    }
    initialFetch()
  }, [apiAdminGetSeries, apiAdminGetTags])

  const openCreateSeriesModal = () => {
    toggleCreateSeriesModalOpen(true)
  }

  const closeCreateSeriesModal = () => {
    toggleCreateSeriesModalOpen(false)
  }

  const addNewSeries = () => {
    resetNewSeries()
    openCreateSeriesModal()
  }

  return (
    <div className={classes.root}>
      <LogsTable
        tableLabel="Series"
        tableAriaLabel="series-table"
        tablePaginationProps={{
          count: !!series ? series.length : 0,
        }}
        defaultRowsPerPage={25}
        CustomTableHeader={({ tableLabel }) => (
          <AdminTableHeader
            tableLabel={tableLabel}
            addNewButtonLabel="New series"
            addNewButtonOnClick={addNewSeries}
          />
        )}>
        {({ page, rowsPerPage }) => {
          return (
            <>
              <TableHead>
                <TableRow>
                  <HeaderCell style={{ width: "20%" }} isStartEndPoint>
                    Name
                  </HeaderCell>

                  <HeaderCell style={{ width: "20%" }}>Description</HeaderCell>

                  <HeaderCell style={{ width: "20%" }}>Image</HeaderCell>

                  <HeaderCell style={{ width: "20%" }}>
                    Is Featured ?
                  </HeaderCell>

                  <HeaderCell style={{ width: "20%" }}>
                    Publish status
                  </HeaderCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {Boolean(series.slice().length) ? (
                  series
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((seriesItem) => (
                      <TableRow key={seriesItem.id}>
                        <BodyCell isStartEndPoint>{seriesItem.name}</BodyCell>

                        <BodyCell>
                          <ShortText
                            value={seriesItem.description || "N/A"}
                            textLimit={50}
                          />
                        </BodyCell>

                        <BodyCell>
                          <Stack spacing={1}>
                            <CustomTooltip title={seriesItem.imageUrl}>
                              {!!seriesItem.imageUrl ? (
                                <img
                                  src={seriesItem.imageUrl}
                                  alt="series"
                                  className={classes.tableTrainerCircleImage}
                                />
                              ) : (
                                <span>N/A</span>
                              )}
                            </CustomTooltip>
                          </Stack>
                        </BodyCell>

                        <BodyCell>
                          <SeriesSwitcher seriesItem={seriesItem} />
                        </BodyCell>

                        <BodyCell>
                          <SeriesActions
                            seriesItem={seriesItem}
                            openCreateSeriesModal={openCreateSeriesModal}
                          />
                        </BodyCell>
                      </TableRow>
                    ))
                ) : (
                  <Box pl={3} pt={3}>
                    {firstLoading ? (
                      <CircularProgress size={24} />
                    ) : (
                      <EmptyState title="When you create some series, they will appear here" />
                    )}
                  </Box>
                )}
              </TableBody>
            </>
          )
        }}
      </LogsTable>

      <CreateSeriesModal
        open={isCreateSeriesModalOpen}
        closeModal={closeCreateSeriesModal}
      />
    </div>
  )
})

export { AdminSeriesList }
