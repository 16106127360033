import React from "react"
import { observer } from "mobx-react-lite"
import { Stack, Button } from "@material-ui/core"
import AddIcon from "@mui/icons-material/Add"

import { useStores } from "../../models/root-store"
import { Post } from "../../models/post"
import { FormModal } from "../admin-form-components"
import { AdminConfigForm } from "../admin-config-form"
import { usePostForm } from "./usePostForm"
import { formConfig } from "./form-config"

const CreateUserPostModal = observer(() => {
  const { adminToolsStore } = useStores()
  const {
    newUserPost,
    resetNewUserPost,
    isPostModalOpen,
    toggleModalPostOpen,
  } = adminToolsStore

  const openModal = () => {
    resetNewUserPost()
    toggleModalPostOpen(true)
  }

  const closeModal = () => toggleModalPostOpen(false)

  const { loading, submitted, onCancel, onSubmit } = usePostForm({
    closeModal,
    isModalOpen: isPostModalOpen,
  })

  const { createPostErrors: errors } = newUserPost

  return (
    <>
      <Stack direction="row" mt={2} height={"fit-content"}>
        <Button variant="outlined" startIcon={<AddIcon />} onClick={openModal}>
          Create user post
        </Button>
      </Stack>

      <FormModal
        open={isPostModalOpen}
        modalTitle="Create new user post"
        closeModal={onCancel}
        loading={loading}
        submitted={submitted}
        errors={errors}
        saveButtonLabel="Create user post"
        onSubmit={onSubmit}>
        <Stack width="100%" spacing={2}>
          <AdminConfigForm<Post>
            submitted={submitted}
            values={newUserPost}
            errors={errors}
            isEditMode={false}
            formConfig={formConfig}
          />
        </Stack>
      </FormModal>
    </>
  )
})
export { CreateUserPostModal }
