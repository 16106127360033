import React, { useState } from "react"
import moment from "moment"
import { Stack, Typography, MenuItem } from "@material-ui/core"
import { DateRange } from "@mui/lab/DateRangePicker"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import CalendarTodayIcon from "@mui/icons-material/CalendarToday"
import { observer } from "mobx-react-lite"

import { useStores } from "../../models/root-store"
import {
  // ASCENDING,
  // DESCENDING,
  // SORT_TYPE,
  EMPTY_FILTER,
  GRATITUDE,
  INTENTION,
  SEEDED,
  HIDDEN,
  QUARANTINED,
  POST_TYPE_FILTER,
  FUTURE,
  PAST,
  TODAY,
  YESTERDAY,
  LAST_7_DAYS,
  LAST_30_DAYS,
  CUSTOM_RANGE,
  DATE_RANGE_FILTER,
  NON_SEEDED,
} from "../../models/admin-tools-store/filter-types"
import { getDateRangeLimitByFilterType } from "../../models/admin-tools-store/utils"
import { CustomDateRangePicker } from "../custom-date-range-picker"
import FilterDropdown from "./filter-dropdown"

const filterOptions = [
  { value: GRATITUDE, label: "Gratitude" },
  { value: INTENTION, label: "Intention" },
  { value: SEEDED, label: "Seeded Posts" },
  { value: NON_SEEDED, label: "Non-Seeded Posts" },
  { value: HIDDEN, label: "Hidden Posts" },
  { value: QUARANTINED, label: "Quarantined Posts" },
]

const useStyles = makeStyles((theme) =>
  createStyles({
    filtersContainer: {
      paddingRight: theme.spacing(2),
    },
    filterLabel: {
      fontSize: 22,
      lineHeight: "27px",
      color: "#2D353B",
      fontWeight: "bold",
    },
    customMenuItem: {
      width: "100%",
    },
  }),
)

const dateRangeFilterOptions = [
  { value: FUTURE, label: "Future" },
  { value: PAST, label: "Past" },
  { value: TODAY, label: "Today" },
  { value: YESTERDAY, label: "Yesterday" },
  { value: LAST_7_DAYS, label: "Last 7 Days" },
  { value: LAST_30_DAYS, label: "Last 30 Days" },
]

const FilterPostOptions = observer(() => {
  //local value of date range picker used for setting the range on the calendar picker
  const [rangeValue, setRangeValue] = useState<DateRange<Date>>([null, null])
  //local flag to check if the date range picker is opened
  const [isDatePickerOpen, toggleDatePicker] = useState<boolean>(false)

  const classes = useStyles()

  const { adminToolsStore } = useStores()
  const {
    // sortType,
    // setSortType,
    setEndPostTimestamp,
    postTypeFilter,
    setPostTypeFilter,
    dateRangeFilter,
    setDateRangeFilter,
    setDateRangePoints,
    rangePointsLabel,
    apiGetInitialFeedPosts,
    apiLoadCustomDateRangePosts,
    setEndCustomDateRangePostTimestamp,
    resetCustomDateRangePosts,
    showSearchPostResults,
  } = adminToolsStore

  /**
   *
   * @param value => the value of the date range picker
   * @returns the label of the date range picker.
   * if the value is `CUSTOM_RANGE`, return rangePointsLabel from the store
   * if the value is `EMPTY_FILTER`, return the default label
   * on any other case, find the corresponding value from the options list `dateRangeFilterOptions`
   */
  const getRangeFilterLabel = (value: string) => {
    switch (value) {
      case CUSTOM_RANGE:
        return `Created: ${rangePointsLabel}`
      case EMPTY_FILTER:
        return "All Dates"
      default:
        const selectedOption = dateRangeFilterOptions.find(
          (option) => option.value === value,
        )
        return `Created: ${selectedOption?.label}`
    }
  }

  const getFilterLabel = (value: string) => {
    if (value === EMPTY_FILTER) {
      return "All Posts"
    }

    return `Type: ${value}`
  }

  /**
   * Set manually the date range filter value to `CUSTOM_RANGE` when opening the calendar picker
   */
  const openDateRangePicker = () => {
    toggleDatePicker(true)
    setDateRangeFilter(CUSTOM_RANGE)
  }

  const closeDateRangePicker = () => {
    toggleDatePicker(false)
  }

  const loadPostsByDateFilter = (value: DATE_RANGE_FILTER) => {
    setDateRangeFilter(value)
    resetCustomDateRangePosts()
    if (value === EMPTY_FILTER) {
      apiGetInitialFeedPosts()
      return
    }

    const dateRangeLimit = getDateRangeLimitByFilterType({
      filter: value,
      customRangeValue: rangeValue,
    })
    const newDateRangePoints = dateRangeLimit.map((dateValue) =>
      dateValue ? moment(dateValue).unix() * 1000 : 0,
    )
    const newEndCustomDateRangePostTimestamp = newDateRangePoints[1]
      ? moment(newDateRangePoints[1]).format("X")
      : null

    setDateRangePoints(newDateRangePoints)
    setEndCustomDateRangePostTimestamp(newEndCustomDateRangePostTimestamp)
    apiLoadCustomDateRangePosts()
  }

  /**
   * Save button method handler on calendar picker
   * It saves range values to store as timestamps
   * Also fires api call to fetch posts of the selected range
   */
  const saveRangePoints = () => {
    loadPostsByDateFilter(CUSTOM_RANGE)

    closeDateRangePicker()
  }

  const handleFilterChange = (value) => {
    setPostTypeFilter(value as POST_TYPE_FILTER)

    // If posts are retrieved using the search API,
    // no additional API call should be made when filters change.
    // The filtering is handled locally.

    if (showSearchPostResults) {
      return
    }

    setEndPostTimestamp(null)
    apiGetInitialFeedPosts()
  }

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      className={classes.filtersContainer}>
      <Stack spacing={1}>
        <Typography className={classes.filterLabel}>Filters:</Typography>

        <Stack direction="row" flexWrap="wrap" columnGap={4} rowGap={2}>
          <FilterDropdown
            value={postTypeFilter}
            onChange={handleFilterChange}
            options={filterOptions}
            selectProps={{
              renderValue: getFilterLabel,
            }}
            label="All Posts"
            defaultValue={EMPTY_FILTER}
          />

          <FilterDropdown
            value={dateRangeFilter}
            onChange={(value) =>
              loadPostsByDateFilter(value as DATE_RANGE_FILTER)
            }
            options={dateRangeFilterOptions}
            label="All Dates"
            onClose={closeDateRangePicker}
            defaultValue={EMPTY_FILTER}
            StartIcon={<CalendarTodayIcon />}
            selectProps={{
              renderValue: getRangeFilterLabel,
            }}>
            {/**
             * `CustomDateRangePicker` added as a children to the FilterDropdown
             *    in order to show a menu item inside select list which will toggle the calendar
             */}
            <CustomDateRangePicker
              rangeValue={rangeValue}
              onChange={setRangeValue}
              saveRangePoints={saveRangePoints}
              open={isDatePickerOpen}
              closeDatePicker={closeDateRangePicker}>
              <MenuItem
                value={CUSTOM_RANGE}
                className={classes.customMenuItem}
                onClick={openDateRangePicker}>
                Custom Date Range
              </MenuItem>
            </CustomDateRangePicker>
          </FilterDropdown>
        </Stack>
      </Stack>

      {
        //TODO: Implement proper filtering from the api call
        /* <Stack spacing={1}>
        <Typography className={classes.filterLabel}>Sort by:</Typography>

        <FilterDropdown
          value={sortType}
          onChange={(value) => setSortType(value as SORT_TYPE)}
          options={[
            { value: DESCENDING, label: "Most recent" },
            { value: ASCENDING, label: "Most elder" },
          ]}
        />
      </Stack> */
      }
    </Stack>
  )
})

export default FilterPostOptions
