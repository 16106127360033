import { types, Instance, SnapshotOut } from "mobx-state-tree"
import moment from "moment"

import * as customTypes from "../types"

export const PostReplyModel = types.model("PostReply").props({
  personID: types.string,
  personName: types.string,
  avatarImageURL: types.maybeNull(types.string),
  message: types.maybeNull(types.string),
  type: types.string,
  isAnonymous: types.optional(types.boolean, false),
  isMuted: types.optional(types.boolean, false),
  isDeleted: types.optional(types.boolean, false),
  isEngagementPrompt: types.optional(types.boolean, false),
  isFromCoach: types.optional(types.boolean, false),
})

export interface PostReply extends Instance<typeof PostReplyModel> {}
export interface PostReplySnapshot extends SnapshotOut<typeof PostReplyModel> {}

export const FeedPostModel = types
  .model("FeedPost")
  .props({
    id: types.identifier,
    personID: types.string,
    personName: types.string,
    avatarImageURL: types.maybeNull(types.string),
    message: types.maybeNull(types.string),
    type: types.string,
    numCards: types.optional(types.maybeNull(types.number), 0),
    numHearts: types.optional(types.maybeNull(types.number), 0),
    isAnonymous: types.optional(types.boolean, false),
    isMuted: types.optional(types.boolean, false),
    isDeleted: types.optional(types.boolean, false),
    isBanned: types.optional(types.boolean, false),
    isEngagementPrompt: types.optional(types.boolean, false),
    favorited: types.optional(types.boolean, false),
    cardSent: types.optional(types.boolean, false),
    isSaved: types.optional(types.boolean, false),
    hasReplied: types.optional(types.boolean, false),
    isFromCoach: types.optional(types.boolean, false),
    inReplyToID: types.optional(types.maybeNull(types.string), null),
    inReplyTo: types.optional(types.maybeNull(PostReplyModel), null),
    createdAt: types.maybeNull(customTypes.iso8601),
  })
  .actions((self) => ({
    toggleIsMuted() {
      self.isMuted = !self.isMuted
    },
    toggleIsBanned() {
      self.isBanned = !self.isBanned
    },
  }))
  .views((self) => ({
    get createdAtFromNow() {
      return self.createdAt && moment(self.createdAt).fromNow()
    },
  }))

export interface FeedPost extends Instance<typeof FeedPostModel> {}
export interface FeedPostSnapshot extends SnapshotOut<typeof FeedPostModel> {}
