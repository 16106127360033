import { useRef, useMemo, useState } from "react"
import {
  AutocompleteChangeReason,
  AutocompleteChangeDetails,
  Chip,
  Theme,
  CSSObject,
} from "@material-ui/core"
import makeStyles from "@material-ui/styles/makeStyles"
import createStyles from "@material-ui/styles/createStyles"
import clsx from "clsx"
import { debounce } from "rambdax"
import { useSnackbars } from "../../components/use-snackbar"
import { useStores } from "../../models/root-store"
import { FieldTypes } from "../../components/admin-config-form"

import { TriIdea } from "../../models/tri-idea"

const useStyles = makeStyles<Theme>((theme: Theme) =>
  createStyles({
    recipesTag: { backgroundColor: "#FCD7D9" },
    hacksTag: { backgroundColor: "#D5E5FF" },
    productsTag: { backgroundColor: "#FFE8CC" },
    itemsCounterContainer: {
      display: "flex",
      flex: 1,
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  }),
)

const useHowTosSelectInput = ({
  ideas,
  setIdeas,
  apiAddIdea,
  apiDeleteIdea,
}: {
  ideas: TriIdea[]
  setIdeas: (value: TriIdea[]) => void
  apiAddIdea: (ideaID: string) => Promise<void>
  apiDeleteIdea: (ideaID: string) => Promise<void>
}) => {
  const { setAndShowSnackbar } = useSnackbars()
  const classes = useStyles()
  const { adminMslEventStoreModel } = useStores()

  const [howTosFilter, setHowTosFilter] = useState("all")
  const [isEditingHowTos, toggleIsEditingHowTos] = useState(false)

  const { triIdeasPagination } = adminMslEventStoreModel

  const handleIdeasChange = async (
    event: React.SyntheticEvent<Element, Event>,
    value: TriIdea[],
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<TriIdea>,
  ) => {
    event.preventDefault()
    const areValidIdeas = value.every((e) => e?.id)
    if (areValidIdeas) {
      setIdeas(value.slice())
    }

    const { option } = details
    try {
      toggleIsEditingHowTos(true)
      if (reason === "selectOption") {
        await apiAddIdea(option.id)
        setAndShowSnackbar({
          text: `Successfully added ${option.title} idea !`,
        })
      }
      if (reason === "removeOption") {
        await apiDeleteIdea(option.id)
        setAndShowSnackbar({
          text: `Successfully removed ${option.title} idea !`,
        })
      }
      toggleIsEditingHowTos(false)
    } catch (error) {
      toggleIsEditingHowTos(false)
      setAndShowSnackbar({ text: error.message, severity: "error" })
    }
  }

  const debouncedSearch = useRef(
    debounce(async (query: string, searchAPI) => {
      try {
        if (query?.trim().length) {
          await searchAPI()
        }
      } catch (error) {
        setAndShowSnackbar({ text: error.message, severity: "error" })
      }
    }, 1000),
  )

  const handleIdeasSearch = async (_, value, isEndReached) => {
    const {
      next,
      query,
      setQuery,
      resetPagination,
      apiAdminGetTriIdeasSearch,
    } = triIdeasPagination

    if (isEndReached) {
      if (query.trim().length) {
        await apiAdminGetTriIdeasSearch()

        return
      }

      if (next) {
        await triIdeasPagination.apiAdminGetTriIdeas()
      }
    } else {
      resetPagination()
      setQuery(value)
      const fetchNewIdeas = () => {
        apiAdminGetTriIdeasSearch()
      }
      debouncedSearch.current(value, fetchNewIdeas)
    }
  }

  const handleOnBlurSelect = () => {
    triIdeasPagination.resetTriIdeas()
    triIdeasPagination.resetPagination()
  }

  const handleSelectFocus = () => {
    if (!triIdeasPagination.query.trim().length) {
      triIdeasPagination.resetPagination()
    }
  }

  const selectOptions = triIdeasPagination.ideas.slice()
  const selectValues = useMemo(() => {
    if (howTosFilter === "all") {
      return ideas.slice()
    }
    return ideas.slice().filter((ideaItem) => ideaItem.type === howTosFilter)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ideas, ideas.slice(), howTosFilter])

  const itemsCounterLabel = useMemo(() => {
    if (howTosFilter === "product") {
      return "Number of Products:"
    }
    if (howTosFilter === "recipe") {
      return "Number of Recipes:"
    }
    if (howTosFilter === "hack") {
      return "Number of Hacks:"
    }
    return "Total Number of Items:"
  }, [howTosFilter])

  const howTosSelectInputConfig = useMemo(() => {
    return {
      fieldName: "ideas",
      onChangeMethod: "setIdeas",
      label: "How Tos",
      fieldType: FieldTypes.Select,
      componentProps: {
        multiple: true,
        forbidDuplicates: true,
        disableClearable: true,
        options: selectOptions,
        value: selectValues,
        onChange: handleIdeasChange,
        loading: triIdeasPagination.loading,
        open: selectOptions.length > 0,
        inputValue: triIdeasPagination.query,
        onInputChange: (_, value, reason) => {
          if (reason === "reset") {
            return
          }

          handleIdeasSearch(_, value, false)
        },
        onScrollEnd: () => handleIdeasSearch(null, null, true),
        onFocus: handleSelectFocus,
        onBlur: handleOnBlurSelect,
        filterOptions: (options: TriIdea[]) => {
          return options
        },
        renderOption: (props, option: TriIdea) => (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        ),
        filterInputStyle: clsx({
          [classes.productsTag]: howTosFilter === "product",
          [classes.recipesTag]: howTosFilter === "recipe",
          [classes.hacksTag]: howTosFilter === "hack",
        }),
        itemCounter: (
          <div className={classes.itemsCounterContainer}>
            {itemsCounterLabel} <b> {selectValues.slice().length}</b>
          </div>
        ),
        disabled: howTosFilter !== "all",
        sx: {
          "& .MuiOutlinedInput-root.Mui-disabled:hover::after": {
            content: `"Select 'All' from the filter menu to edit How-Tos"`,
            position: "absolute",
            bottom: "105%",
            left: 0,
            backgroundColor: "rgba(0, 0, 0, 0.7)",
            WebkitTextFillColor: "#fff",
            padding: 1,
            borderRadius: 4,
            fontSize: 12,
          } as CSSObject,
        } as CSSObject,
        getOptionLabel: (option: TriIdea) => option.title,
        filters: [
          {
            label: "Filter by",
            key: "filterBy",
            value: howTosFilter,
            options: [
              { label: "All", value: "all" },
              { label: "Recipes", value: "recipe" },
              { label: "Hacks", value: "hack" },
              { label: "Products", value: "product" },
            ],
            InputProps: {
              disabled: triIdeasPagination.loading || isEditingHowTos,
              sx: {
                position: "relative",
                "& .MuiInputBase-input.Mui-disabled:hover::after": {
                  content: '"Filter is disabled while editing how-tos"',
                  position: "absolute",
                  bottom: "110%",
                  left: "50%",
                  backgroundColor: "rgba(0, 0, 0, 0.7)",
                  WebkitTextFillColor: "#fff",
                  padding: 1,
                  borderRadius: 4,
                  fontSize: 12,
                } as CSSObject,
              } as CSSObject,
            },
          },
        ],
        renderTags: (value: TriIdea[], getTagProps) =>
          value.map((option: TriIdea, index) => (
            <Chip
              label={option["title"]}
              color="secondary"
              classes={{
                colorSecondary: clsx({
                  [classes.productsTag]: option.type === "product",
                  [classes.recipesTag]: option.type === "recipe",
                  [classes.hacksTag]: option.type === "hack",
                }),
              }}
              {...getTagProps({ index })}
            />
          )),
        onFilterChange: (filterName, filterValue) => {
          setHowTosFilter(filterValue)
        },
        Input: {
          placeholder: "Select How-Tos",
        },
        showTags: true,
        tagDisplayNameField: "title",
      },
      showOnlyOnEditMode: true,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectValues, selectOptions, triIdeasPagination.loading])

  return {
    howTosSelectInputConfig,
  }
}

export { useHowTosSelectInput }
