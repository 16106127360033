import { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import createStyles from "@material-ui/styles/createStyles"
import makeStyles from "@material-ui/styles/makeStyles"
import AddIcon from "@mui/icons-material/Add"

import { Theme, Stack, Typography, Button } from "@material-ui/core"
import { GridCellProps, useGridApiRef } from "@mui/x-data-grid"
import { useStores } from "../../models/root-store"
import { typography } from "../../services/theme/typography"

import { CustomGridTable, TableCell } from "../custom-grid-table"
import { CellComponent } from "./cell-component"
import { LIMIT } from "../../models/tri-ideas-pagination"
import { CreateTriProductModal, ModalMode } from "./create-tri-product-modal"
import { CreateTriNestedProductModal } from "./create-tri-nested-product"
import { useTable } from "./useTable"
import { columns } from "./table-config"
import { TriProduct } from "../../models/tri-product"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flex: "1 1 1px",
      flexDirection: "column",
      overflow: "auto",
      padding: theme.spacing(4, 12, 7, 6),
    },
    tableLabel: {
      fontSize: theme.spacing(2.5),
      lineHeight: theme.spacing(3),
      color: theme.palette.text.secondary,
      ...typography.circularXXMedium,
    },
    label: {
      fontWeight: "bold",
      fontSize: theme.spacing(2.25),
    },

    tableCell: {
      width: "17% !important",
      maxWidth: "17% !important",
      display: "flex",
      borderBottom: "unset",
      wordBreak: "break-word",
      color: "white !important",
    },
    tableColumnHeader: {
      width: "17% !important",
      minWidth: "unset !important",
      maxWidth: "unset !important",
      height: "unset !important",
    },

    staleWithBackup: {
      background: "#b2b200",

      "&:hover": {
        backgroundColor: "#cccc00 !important",
      },
    },

    staleWithoutValidBackup: {
      background: "#ff3232",
      "&:hover": {
        backgroundColor: "#ff4c4c !important",
      },
    },

    liveOnApp: {
      background: "#329932",
      "&:hover": {
        backgroundColor: "#4ca64c !important",
      },
    },
  }),
)

const AdminTriProductsList = observer(() => {
  const tableRef = useGridApiRef()

  const classes = useStyles()
  const [firstLoading, toggleFirstLoading] = useState(true)
  const [
    isCreateTriProductModalOpen,
    toggleCreateTriProductModalOpen,
  ] = useState(false)
  const [triProductModalMode, setTriProductModalMode] = useState<ModalMode>(
    ModalMode.CREATE,
  )

  const restorePaginationState = () => {
    const state = tableRef.current.exportState()

    const restoredState = {
      ...state,
      pagination: {
        ...state.pagination,
        paginationModel: {
          ...state.pagination?.paginationModel,
          page: 0,
        },
      },
    }
    tableRef.current.restoreState(restoredState)
  }

  const { adminMslEventStoreModel } = useStores()
  const {
    triProductsPagination,
    triIdeaProductsPagination,
  } = adminMslEventStoreModel
  const {
    nestedTriProduct,
    setNewTriProduct,
    resetNewTriProduct,
  } = triProductsPagination

  const { apiAdminGetTriProducts } = triProductsPagination

  const {
    page: currentPage,
    pageSize: currentPageSize,
    setLoading,
    setPage,
    setPageSize,
    resetPagination,
  } = triProductsPagination

  const {
    query: productsSearchQuery,
    setPage: productsSearchSetPage,
    setLimit: productsSearchSetLimit,
    setNext: productsSearchSetNext,
    resetTriProducts: productsSearchResetProducts,
    apiAdminGetTriIdeaProducts,
  } = triIdeaProductsPagination

  const searchByQueryIsActive = productsSearchQuery.trim().length

  useEffect(() => {
    fetchProducts()

    return () => {
      resetPagination()
      triIdeaProductsPagination.resetPagination()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchProducts = async () => {
    resetPagination()
    triIdeaProductsPagination.resetPagination()

    try {
      await apiAdminGetTriProducts()
      toggleFirstLoading(false)
      setLoading(false)
    } catch (e) {
      toggleFirstLoading(false)
      setLoading(false)
    }
  }

  const openCreateTriProductModal = () => {
    resetNewTriProduct()
    setTriProductModalMode(ModalMode.CREATE)
    toggleCreateTriProductModalOpen(true)
  }

  const openEditTriProductModal = useCallback((event: TriProduct) => {
    setNewTriProduct(event)
    setTriProductModalMode(ModalMode.EDIT)
    toggleCreateTriProductModalOpen(true)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const closeCreateTriProductModal = () => {
    toggleCreateTriProductModalOpen(false)
  }

  const handlePageSizeChange = async (pageSize: number) => {
    restorePaginationState()

    resetPagination()

    productsSearchSetLimit(pageSize)
    setPageSize(pageSize)

    if (searchByQueryIsActive) {
      productsSearchSetNext(0)
      productsSearchResetProducts()

      await apiAdminGetTriIdeaProducts()

      return
    }

    await apiAdminGetTriProducts()
  }

  const handleNextPageChange = async (page: number) => {
    setPage(page + 1)
    productsSearchSetPage(page + 1)

    if (searchByQueryIsActive) {
      await apiAdminGetTriIdeaProducts()

      return
    }

    await apiAdminGetTriProducts()
  }

  const handlePaginationChange = async (pageData, reasonData) => {
    const { page, pageSize } = pageData
    const { reason } = reasonData

    if (reason !== "setPaginationModel") {
      return
    }

    if (pageSize !== currentPageSize) {
      handlePageSizeChange(pageSize)
    }

    if (currentPage < page + 1) {
      handleNextPageChange(page)
    }
  }

  const MemoTableCell = useCallback(
    (props: GridCellProps) => (
      <TableCell {...props} tableCellClass={classes.tableCell}>
        <CellComponent
          {...props}
          openEditTriProductModal={openEditTriProductModal}
          triProductID={props.rowId as string}
        />
      </TableCell>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const { handleFilterModelChange } = useTable({
    restorePaginationState,
  })

  const productStaleStatus = (params) => {
    const { backups, isStale } = params.row

    const hasBackups = backups?.length > 0
    const hasStaleAllBackups = (backups || []).every((backup) => backup.isStale)

    const isProductNotActive =
      (isStale && hasStaleAllBackups) || (isStale && !hasBackups)

    if (!isStale) {
      return classes.liveOnApp
    }

    if (isProductNotActive) {
      return classes.staleWithoutValidBackup
    }

    if (isStale && hasBackups && !hasStaleAllBackups) {
      return classes.staleWithBackup
    }
  }

  const indexTableData = triIdeaProductsPagination.query.trim().slice().length
    ? triIdeaProductsPagination
    : triProductsPagination

  const emptyStateLabel = triIdeaProductsPagination.query.trim().slice().length
    ? "No matching Products found"
    : "When you create some products, they will appear here"

  return (
    <div className={classes.root}>
      <Stack
        direction="row"
        flex={1}
        justifyContent="space-between"
        alignItems="center">
        <Typography className={classes.tableLabel}>Products</Typography>

        <Button
          variant="outlined"
          startIcon={<AddIcon />}
          onClick={openCreateTriProductModal}>
          Add product
        </Button>
      </Stack>

      <CustomGridTable
        apiRef={tableRef}
        columns={columns}
        aria-label="tri-products-table"
        cell={MemoTableCell}
        rows={indexTableData.loading ? [] : indexTableData.products.slice()}
        getRowClassName={productStaleStatus}
        onPaginationModelChange={handlePaginationChange}
        rowCount={indexTableData.totalCount}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: LIMIT,
            },
          },
        }}
        pageSizeOptions={[25, 50, 100]}
        emptyStateLabel={emptyStateLabel}
        loading={indexTableData.loading || firstLoading}
        classes={{
          columnHeader: classes.tableColumnHeader,
        }}
        sortingMode="server"
        filterMode="server"
        onFilterModelChange={handleFilterModelChange}
      />

      {isCreateTriProductModalOpen && (
        <CreateTriProductModal
          open={isCreateTriProductModalOpen}
          closeModal={closeCreateTriProductModal}
          mode={triProductModalMode}
        />
      )}

      {nestedTriProduct.id !== "-1" && (
        <CreateTriNestedProductModal open={nestedTriProduct.id !== "-1"} />
      )}
    </div>
  )
})

export { AdminTriProductsList }
